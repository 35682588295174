import React, { useEffect, useState } from "react";
import { Button, Modal, Popover, message } from "antd";
import strings from "../../../../../../../../../../strings";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { LIST_PARTNERS } from "../../../../constants";

const TierDetailModal = ({
  visible,
  closeModal,
  currentTier,
  toggleEditTierModal,
  toggleDeleteTierModal,
  toggleViewMembers,
  is_owner,
}) => {
  const {
    created_at,
    customersEnrolled,
    instruction,
    name,
    reward,
    min_spending_target,
    max_spending_target,
    magnification
  } = currentTier;

  const [partner, setPartner] = useState(null);

  const { loading: partnersDataLoading, data: partnersData } = useQuery(
    LIST_PARTNERS,
    {
      onError(error) {
        message.error(error.message, 5);
      },
    }
  );
  useEffect(() => {
    if (currentTier.is_reward_self === true) {
      return;
    } else {
      const data = partnersData;
      !partnersDataLoading &&
        data.listPartners &&
        data.listPartners.length > 0 &&
        data.listPartners.forEach(partner => {
          partner.tier &&
            partner.tier.length > 0 &&
            partner.tier.forEach(tier => {
              if (tier.id === currentTier.id) {
                setPartner(partner);
              }
            });
        });
    }
  }, [currentTier.id, currentTier.is_reward_self, partnersData, partnersDataLoading]);


  const menu = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        key={1}
        icon={<EditOutlined />}
        onClick={toggleEditTierModal}
        disabled={!is_owner}
      >
        {strings.edit}
      </Button>
      <Button
        key={2}
        icon={<DeleteOutlined />}
        onClick={toggleDeleteTierModal}
        disabled={!is_owner}
      >
        {strings.delete}
      </Button>

      <Button key={3} onClick={toggleViewMembers}>
        {strings.viewMembers}
      </Button>
    </div>
  );

  return (
    <Modal
      title={`${currentTier.name}`}
      className="tier_details"
      open={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          {strings.cancel}
        </Button>,
        <Popover key="actions" title="Actions" content={menu}>
          <Button
            id="btn-component"
            key={"action-btn"}
            className="ant-btn-text"
            type="primary"
          >
            {strings.actions}
          </Button>
        </Popover>,
      ]}
    >
      <div>
        <p>
          <strong>{strings.nameOfTier}:</strong> {name}
        </p>
        <p>
          <strong>{strings.minimumSpendingTarget}:</strong>{" "}
          {min_spending_target}
        </p>
        <p>
          <strong>{strings.maximumSpendingTarget}:</strong>{" "}
          {max_spending_target}
        </p>
        <p>
          <strong>{strings.magnification}:</strong>{" "}
          {magnification}
        </p>
        <p>
          <strong>{strings.membersEnrolled}:</strong> {customersEnrolled}
        </p>
        <p>
          <strong>{strings.rewards}:</strong> {reward}
        </p>
        <p>
          <strong>{strings.instruction}:</strong> {instruction}
        </p>
        {partner && (
          <p>
            <strong>{strings.partner}:</strong> {partner.company_name}
          </p>
        )}
        <p>
          <strong>{strings.createdOn}:</strong>{" "}
          {new Date(created_at).toLocaleString()}
        </p>
      </div>
    </Modal>
  );
};

export default TierDetailModal;
