import { Button, DatePicker, Form, Input, InputNumber, message, Modal, Select } from 'antd'
import React, { useRef, useState } from 'react'
import strings from '../../../../../strings';
import "./index.scss";
import ManageReturnModal from '../../Products/_partials/ManageReturnModa';
import moment from 'moment';
import { EditOutlined } from "@ant-design/icons";
import ReactToPrint from 'react-to-print';
import {
  PrinterOutlined,
} from "@ant-design/icons";
import Receipt from './Receipt';

const FormItem = Form.Item
const PurchaseDetails = ({
    open,
    onCancel,
    purchaseOrder,
    user,
    returnPurchaseOrder,
    refetch,
    backdatePurchaseOrder,
    handleFetch
}) => {
  const [form] = Form.useForm();
  const componentRef = useRef();
  const [ loading, setLoading ] = useState(false);
  const [ isOthers, setIsOthers ] = useState(false);
  const [ showReturnModal, setShowReturnModal ] = useState(false);
  const [ selectedItem, setSelectedItem ] = useState(null);
  const reasons = [strings.itemIsDamaged, strings.itemDoesNotFit, strings.others]
  const handleReturnOrder = (item) => {
    setSelectedItem(item);
    setShowReturnModal(true);
  }
  const [ showBackdate, setShowBackdate ] = useState(false);
  const [ selectedDate, setSelectedDate ] = useState("");
  const [ dateLoading, setDateLoading ] = useState(false);

  const productStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "5px"
  }

  const handleFindReceivedHistories = (purchaseOrder) => {
    if(purchaseOrder.purchase_order_histories && purchaseOrder.purchase_order_histories.length > 0){
      const receivedHistory = purchaseOrder.purchase_order_histories.find(history => history.status === "merchant_received");
      if(!receivedHistory) return "-";
      return moment(receivedHistory.updated_at).format("YYYY/MM/DD h:mm:ss A")
    }
  }

  const handleFindReceivedHistoriesPaid = (purchaseOrder) => {
    if(purchaseOrder.purchase_order_histories && purchaseOrder.purchase_order_histories.length > 0){
      const receivedHistory = purchaseOrder.purchase_order_histories.find(history => history.status === "paid");
      if(!receivedHistory) return "-";
      return moment(receivedHistory.updated_at).format("YYYY/MM/DD h:mm:ss A")
    }
  }

  const handlePurchaseForSupplier = () => {
    const url = `/d/purchase-orders/${purchaseOrder.uuid}`
    Modal.info({
      title: strings.youAreAboutToAcceptForSupplier,
      onOk: () => {
        window.open(url, "_blank");
      },
      okText: "Continue",
      closable: true
    })
  }

  const handleUpdateReceiveddate = () => {
   if(!selectedDate) return message.info("Kindly set date to continue!");

   backdatePurchaseOrder({ id: purchaseOrder.id, received_date: selectedDate })
   .then(res => {
    if(res.status === 200 || res.status === 201){
      message.success("Date has been set!");
      handleFetch();
    }
   })
   .catch(err => {
      message.error(`${err?.response && err?.response?.data?.message || `Unable to mark as delivered - ${err.message}`}`)
      setDateLoading(false);
   })
  }

  return (
  <>
    <Modal
      open={open}
      onCancel={onCancel}
      title={`${strings.purchaseOrderHistory} - ${purchaseOrder && purchaseOrder.user && purchaseOrder.user.first_name}`}
      footer={(
        <>
        <ReactToPrint
          trigger={() => (
            <Button style={{ marginTop: 20 }} icon={<PrinterOutlined />}>
              {strings.printReceipt}
            </Button>
          )}
          content={() => componentRef.current}
        />
        <div style={{ display: "none" }}>
          <Receipt
            purchaseOrder={purchaseOrder}
            user={user}
            ref={componentRef}
            handleFindReceivedHistories={handleFindReceivedHistories}
            handleFindReceivedHistoriesPaid={handleFindReceivedHistoriesPaid}
          />
        </div>
      </>
      )}
    >
      <div className="images">
        <div style={{display: "flex", alignItems: "center", gap:20}}>

      
        </div>
      
        <p>
          <strong>{strings.supplier}</strong>: {purchaseOrder && purchaseOrder.user && purchaseOrder.user.first_name } {purchaseOrder && purchaseOrder.user && purchaseOrder.user.last_name || ""}
        </p>
        <p>
          <strong>{strings.businessName}</strong>: {purchaseOrder && purchaseOrder.supplier && purchaseOrder.supplier.business_name}
        </p>
        
        <p>
          <strong>{strings.status}</strong>: {purchaseOrder &&purchaseOrder.status}
        </p>
        
        <p>
          <strong>{strings.receivedBy}</strong>: {purchaseOrder && purchaseOrder.purchase_order_histories.length > 0 ? purchaseOrder.purchase_order_histories[purchaseOrder.purchase_order_histories.length - 1].triggered_by || "N/A" : "N/A"}
        </p>

        {purchaseOrder && purchaseOrder.purchase_order_histories.length > 3 && (
          <p>
            <strong>{strings.dateReceived}</strong>: {handleFindReceivedHistories(purchaseOrder)}
            <span> <EditOutlined style={{cursor: "pointer"}} onClick={() => setShowBackdate(true)} /> </span>
          </p>
        )}

        {purchaseOrder && purchaseOrder.purchase_order_histories.length > 4 && (
          <p>
            <strong>{strings.datePaid}</strong>: {handleFindReceivedHistoriesPaid(purchaseOrder)}
          </p>
        )}

        {purchaseOrder && purchaseOrder.purchase_order_histories.length === 1 && (
          <p style={{fontStyle: "italic", fontSize: 14, cursor: "pointer"}} onClick={handlePurchaseForSupplier}>{strings.acceptPurchaseOrderForSupplier}</p>
        )}

        <div>
          <span>
              <strong>{strings.orderedItems}</strong>
          </span>

          <ul style={{...productStyle, listStyleType: "circle"}} className='supplier_prod'>
            {purchaseOrder && purchaseOrder.purchase_order_items.map((purchase, index) => {
                return (
                    <li key={index} style={{display: "flex", justifyContent: "space-between", listStyleType: "circle"}}>
                      <div style={{display: "flex", flexDirection: "column",listStyleType: "circle"}}>
                        <span style={{fontSize: 14}}>
                          {purchase.name} - {user.currency || user.employer.currency} {purchase.price}
                        </span>
                        <div style={{display: "flex", alignItems: "center", gap:8}}>
                          <span  style={{fontSize: 12}}>
                              <strong>{strings.orderedQty}</strong> - {purchase.ordered_quantity}
                          </span>
                          <span style={{fontSize: 12}}>
                          <strong>{strings.receivedQty}</strong> - {purchase.received_quantity || 0}
                          </span>
                          {purchase.order_return_item && <span style={{fontSize: 12}}>
                            <strong>{strings.returnedQty}</strong> - {purchase.returned_quantity || 0}
                          </span>}
                        </div>
                      </div>

                      {!purchase.order_return_item && purchaseOrder.status === "merchant_received" &&  <div>
                        <Button type="link" onClick={() => handleReturnOrder(purchase)} loading={loading}>{strings.returnItem}</Button>
                      </div>}
                      
                    </li>
                )
            })}
          </ul>
        </div>
      </div>
    </Modal>
  
    {showBackdate && (
      <Modal
        open={showBackdate}
        onCancel={() => setShowBackdate(false)}
        title={strings.backdatePurchaseOrder}
        onOk={handleUpdateReceiveddate}
        confirmLoading={dateLoading}
      >
        <div>
          <DatePicker 
            onChange={(date, dateString) => {
              setSelectedDate(dateString);
            }}
            style={{ width: "100%" }}
          />
        </div>
      </Modal>
    )}

    {showReturnModal && (
      <ManageReturnModal 
        open={showReturnModal}
        onCancel={() => setShowReturnModal(false)}
        item={selectedItem}
        returnPurchaseOrder={returnPurchaseOrder}
        refetch={() => {
          refetch()
          onCancel();
        }}
        isPurhaseOrder
        purchaseOrder={purchaseOrder}
      />
    )}
  </>
  )
}

export default PurchaseDetails;