import React from "react";
import moment from "moment";
import { Button, message, Radio, Tag} from "antd";
import strings from "../../../../strings";

const paymentMethod = [
  "Cash",
  "Card",
  "Wallet",
  "Point",
  "Bank Transfer",
  "Bank Transfer (USSD)",
];

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

export const getTableColumns = function () {
  const { allBranches, selectedBranch, handleResetTransactions, todaysReport } = this.props;
  const columns = [
    {
      title: strings.transactionId,
      dataIndex: "id",
      render: (record) =>
        record ? <span>{record}</span> : <span>N/A</span>,
      ...this.getColumnSearchProps("id"),
    },
    {
      title: strings.customerName,
      dataIndex: "customerName",
      render: (record) =>
        record ? <span>{record}</span> : <span>Guest Customer</span>,
      ...this.getColumnSearchProps("customerName"),
    },
    {
      title: strings.amount,
      dataIndex: "total",
      key: "total",
      render: (record, value) => {
        return <span>{Number(record).toFixed(2)}</span>;
      },
      sorter: (a, b) => {
        return Number(a.total) - Number(b.total);
      },
    },
    {
      title: strings.vat,
      dataIndex: "vat",
      key: "vat",
      render: (record, value) => {
        return <span>{Number(record).toFixed(2)}</span>;
      },
      sorter: (a, b) => {
        return Number(a.total) - Number(b.total);
      },
    },
    {
      title: strings.paymentType,
      dataIndex: "paymentType",
      render: (record, details) => (record ? <span>
{record}
{details.payment_label && <Tag className="ml-1" color="green">
             {details.payment_label.label_name}
          </Tag>}
</span> : <span>N/A</span>),
      onFilter: (value, record) => {
        // return String(record.paymentType).indexOf(value) === 0;
        return String(record.paymentType).toLowerCase().includes(value.toLowerCase());
      },//todaysReport ? null :
      filterDropdown: (...props) => {
        const { clearFilters, confirm, setSelectedKeys, selectedKeys } =
          props[0];

        // console.log(props);

        return (
          <section className="filter-container">
            <Radio.Group
              onChange={(e) => {
                setSelectedKeys([e.target.value]);
              }}
              value={selectedKeys[0]}
              style={{ padding: "0 10px" }}
            >
              {Array.isArray(paymentMethod) &&
                paymentMethod.map((filter, key) => (
                  <Radio key={key + filter} style={radioStyle} value={filter}>
                    {filter}
                  </Radio>
                ))}
                <Radio key={"+"} style={radioStyle} value={"+"}>
                  Split payment
                </Radio>
            </Radio.Group>

            <footer
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderTop: "solid 1px #E6E6E6",
              }}
            >
              <Button
                type="link"
                onClick={() => {
                  this.props.updateFilterValue(selectedKeys[0]);
                  // confirm();
                }}
              >
                Ok
              </Button>
              <Button
                type="link"
                onClick={() => {
                  this.props.updateFilterValue(null);
                  clearFilters();
                  confirm();
                }}
              >
                {strings.reset}
              </Button>
            </footer>
          </section>
        );
      },
      // sorter: (a, b) => a.name.length - b.name.length,
      filterMultiple: false,
    },
    // {
    //   title: strings.discountedSales,
    //   dataIndex: "has_discount",
    //   render: (record) => (record ? <span>Yes</span> : <span>No</span>),
    // },
    {
      title: strings.branch,
      dataIndex: "business_branch",
      render: (record) => (record ? <span>{record.name}</span> : <span>Home Branch</span>),
      onFilter: (value, record) => {
        // return String(record.paymentType).indexOf(value) === 0;
        return record.id === value;
      },
      filterDropdown: (...props) => {
        const { clearFilters, confirm, setSelectedKeys, selectedKeys } =
          props[0];
        return (
          <section className="filter-container">
            <Radio.Group
              onChange={(e) => {
                setSelectedKeys([e.target.value]);
              }}
              value={selectedKeys.length > 0 ? selectedKeys[0] : selectedBranch}
              style={{ padding: "0 10px" }}
              
            >
              {allBranches && allBranches.length > 0 && allBranches.map((filter, key) => (
                  <Radio key={filter.id} style={radioStyle} value={filter.id}>
                    {filter.name}
                  </Radio>
                ))}
            </Radio.Group>

            <footer
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderTop: "solid 1px #E6E6E6",
              }}
            >
              <Button
                type="link"
                onClick={() => {  
                  this.props.handleBranchSelect(selectedKeys[0]);
                  // this.props.updateFilterValue(selectedKeys[0]);
                  // confirm();
                }}
              >
                Ok
              </Button>
              <Button
                type="link"
                onClick={() => {
                  clearFilters();
                  confirm();
                  handleResetTransactions && handleResetTransactions();
                }}
              >
                {strings.reset}
              </Button>
            </footer>
          </section>
        );
      },
      // sorter: (a, b) => a.name.length - b.name.length,
      filterMultiple: false,
      className: this.props.handleBranchSelect && !this.props.user.business_branch ? "show" : "hide"
    },
    {
      title: strings.branch,
      dataIndex: "business_branch",
      render: (record) => (record ? <span>{record.name}</span> : <span>Home Branch</span>),
      className: !this.props.handleBranchSelect || this.props.user.business_branch ? "show" : "hide"
    },
    {
      title: strings.createdDate,
      dataIndex: "createdDate",
      render: (record) => (record ? <span>{record}</span> : <span>N/A</span>),
      sorter: (a, b) => {
        return moment(a.createdDate) < moment(b.createdDate);
      },
    },
    {
      title: strings.createdTime,
      dataIndex: "createdTime",
      render: (record, value) => (record ? <span>{record}</span> : <span>N/A</span>),
      sorter: (a, b) => {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
      },
    },
    {
      title: strings.servedBy,
      dataIndex: "staff",
      ...this.getColumnSearchProps("staff"),
    },
  ];
  return columns;
};

export const handleDurationChange = function (duration, ...rest) {
  const { user, allBranches } = this.props;
  const { isBranchSelected, selectedBranch, selectedStaffDetails, isStaffSelected,selectedPaymentType,transactionId,filterByVat } = this.state;
  let staffId = selectedStaffDetails ? selectedStaffDetails?.staff_id : null 
  if(selectedPaymentType){
    this.handleSelectPaymentType(true, selectedPaymentType);
  }else{
    this.updateFilterValue("");
  }

  let begin = null;
  let end = null;
  if(duration){
    // const [begin, end] = duration;
    begin = duration[0];
    end = duration[1];
  }
  
  if (begin && end) {
    this.setState({
      loading: true,
    });

    if(user.business_branch){
      this.props
      .getBranchSalesByDuration(
        begin.format().split("T")[0],
        end.format().split("T")[0],
        user.business_branch.id,
        1,
        "default",
        selectedPaymentType,
        staffId,
        transactionId,
        filterByVat
      )
      .then((response) => {
        this.setState({
          reports: parseReport(response.data),
          totalRecords: response.headers.total,
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalProfit: response.headers["total-range-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard:
            response.headers["total-range-amount-with-card"],
          totalPaymentWithCash:
            response.headers["total-range-amount-with-cash"],
          totalPaymentWithMobile:
            response.headers["total-range-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-range-amount-with-mtransfer"],
          begin,
          end,
        });
      })
      .catch(() => {
        message.error(strings.errorFetchingRecord);
      });
    }else if(isBranchSelected){
      this.props
      .getBranchSalesByDuration(
        begin.format().split("T")[0],
        end.format().split("T")[0],
        selectedBranch,
        1,
        "default",
        selectedPaymentType,
        staffId,
        transactionId,
        filterByVat
      )
      .then((response) => {
        this.setState({
          reports: parseReport(response.data),
          totalRecords: response.headers.total,
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalProfit: response.headers["total-range-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard:
            response.headers["total-range-amount-with-card"],
          totalPaymentWithCash:
            response.headers["total-range-amount-with-cash"],
          totalPaymentWithMobile:
            response.headers["total-range-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-range-amount-with-mtransfer"],
          begin,
          end,
        });
      })
      .catch(() => {
        message.error(strings.errorFetchingRecord);
      });
    }else if(isStaffSelected){
      this.props.getCashierSales(
        selectedStaffDetails.staff_id,
        selectedStaffDetails.branch_id,
        1,
        begin.format().split("T")[0],
        end.format().split("T")[0],
      )
      .then(response => {
        this.setState({
          reports: parseReport(response?.data),
          totalRecords: Number(response.headers.total),
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalRangeProfit: response.headers["total-range-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard:
            response.headers["total-range-amount-with-card"],
          totalPaymentWithCash:
            response.headers["total-range-amount-with-cash"],
          totalPaymentWithMobile:
            response.headers["total-range-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-range-amount-with-mtransfer"],
          begin,
          end,
          currentPage: 1,
        });
      })
      .catch(() => {
        message.error(strings.errorFetchingRecord);
      });
    }else if(selectedPaymentType){
      const homBranch = allBranches && allBranches.find(branch => branch.name.toLowerCase().includes("home"));

      this.props
      .getBranchSalesByDuration(
        begin.format().split("T")[0],
        end.format().split("T")[0],
        homBranch.id,
        1,
        "default",
        selectedPaymentType,
        staffId,
        transactionId,
        filterByVat
      )
      .then(response => {
        this.setState({
          reports: parseReport(response?.data),
          totalRecords: Number(response.headers.total),
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalRangeProfit: response.headers["total-range-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard:
            response.headers["total-range-amount-with-card"],
          totalPaymentWithCash:
            response.headers["total-range-amount-with-cash"],
          totalPaymentWithMobile:
            response.headers["total-range-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-range-amount-with-mtransfer"],
          begin,
          end,
          currentPage: 1,
        });
      })
      .catch(() => {
        message.error(strings.errorFetchingRecord);
      });
    }else{
      this.props
      .getAllSalesByDuration(
        begin.format().split("T")[0],
        end.format().split("T")[0],
        1,
        "default",
        selectedPaymentType,
        staffId,
        transactionId,
        filterByVat
      )
      .then((response) => {
        this.setState({
          reports: parseReport(response.data),
          totalRecords: response.headers.total,
          loading: false,
          totalAmount: response.headers["total-amount"],
          totalProfit: response.headers["total-range-profit"],
          amountInRange: response.headers["total-range-amount"],
          totalPaymentWithCard:
            response.headers["total-range-amount-with-card"],
          totalPaymentWithCash:
            response.headers["total-range-amount-with-cash"],
          totalPaymentWithMobile:
            response.headers["total-range-amount-with-mobile"],
          totalPaymentWithMTransfer:
            response.headers["total-range-amount-with-mtransfer"],
          begin,
          end,
        });
      })
      .catch(() => {
        message.error(strings.errorFetchingRecord);
      });
    }
  } else if (!begin && !end) {
    this.setState({
      loading: true,
    });
    if(user.business_branch){
      this.handleGetSalesByBranch();
    }else{
      this.handleGetAllSales();
    }
  } else if (!begin || !end) {
    message.error(
      `${strings.pleaseEnterAValid} ${!begin ? strings.begin : strings.end} ${
        strings.date
      }.`
    );
  }
};

function getPaymentType(paymentMethods) {
  const {
    is_paid_with_card,
    is_paid_with_cash,
    is_paid_with_customer_account,
    is_paid_with_point,
    is_paid_with_mtransfer,
    is_paid_with_mobile,
  } = paymentMethods;

  const methods = {
    is_paid_with_point,
    is_paid_with_cash,
    is_paid_with_card,
    is_paid_with_customer_account,
    is_paid_with_mtransfer,
    is_paid_with_mobile
  };

  const paymentTypes = {
    is_paid_with_card: "Card",
    is_paid_with_cash: "Cash",
    is_paid_with_point: "Points",
    is_paid_with_mtransfer: "Bank Transfer",
    is_paid_with_mobile: "Bank Transfer (USSD)",
    is_paid_with_customer_account: "Wallet"
  };

  let paymentType = [];

  for (const key in methods) {
    if (methods[key]) {
      paymentType.push(paymentTypes[key]);
    }
  }

  return paymentType.join(" + ");
}

export const parseReport = function (data) {
  const result = data.length > 0 && data.map((report) => {
    const tempReport = { ...report };
    let productString = "";
    report.transactions &&
      report.transactions.map(
        (txn) =>
          (productString += `${txn.product_name} (${
            (Number(txn.amount) / Number(txn.product_quantity)).toFixed(2) ||
            txn.product?.original_price ||
            txn.product?.price ||
            "No Price"
          } x ${Number(txn.product_quantity).toFixed(1)}), `)
      );
    tempReport.customerName =
      report.customer && report.customer.name
        ? report.customer.name
        : strings.guestCustomer;
    tempReport.createdDate = report.created_at.split("T")[0];
    tempReport.createdTime = moment(report.created_at).format("h:mm:ss A");
    tempReport.paymentType = getPaymentType(report);
    tempReport.staff_email = report.staff ? report.staff.email : "";
    tempReport.products = productString;
    // tempReport.createdAt = report.created_at;
    // tempReport.paymentType = report.is_paid_with_card
    //   ? "Card"
    //   : report.is_paid_with_cash
    //   ? "Cash"
    //   : report.is_paid_with_point
    //   ? "Point"
    //   : report.is_paid_with_mtransfer
    //   ? "Bank Transfer"
    //   : "Bank Transfer (USSD)";

    tempReport.total =
      report.has_discount && !report.invoice_id
        ? Number(report.total) - Number(report.discount_amount)
        : report.total;

    return tempReport;
  });
  
  return result.length > 0 ? result.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()) : [];
};

export const parseExportData = function (data) {
  const exportData = data.map((sale) => {
    const total_cost_price = sale.transactions.length
      ? sale.transactions.reduce((total, transaction) => {
          let variant_cost_price = transaction.product.variant
            ? transaction.product.variant.cost_price
            //  transaction.product_variants.reduce(
            //     (total, variant) => total + Number(variant.cost_price),
            //     0
            //   )
            : 0;

          if (transaction.product && transaction.product.cost_price) {
            return (
              total +
              Number(transaction.product.cost_price) +
              variant_cost_price
            );
          } else {
            return total + 0 + variant_cost_price;
          }
        }, 0)
      : sale.transactions.length;

    const total_selling_price = sale.transactions.length
      ? sale.transactions.reduce((total, transaction) => {
          let variant_selling_price = transaction.product.variant
            ? transaction.product.variant.price
            // transaction.product_variants.reduce(
            //     (total, variant) => total + Number(variant.price),
            //     0
            //   )
            : 0;

          if (transaction.product && transaction.product.price) {
            return (
              total + Number(transaction.product.price) + variant_selling_price
            );
          } else {
            return total + 0 + variant_selling_price;
          }
        }, 0)
      : sale.transactions.length;

    return {
      ...sale,
      total_selling_price,
      total_cost_price,
    };
  });

  return exportData;
};
