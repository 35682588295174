import { gql } from "@apollo/client";

const LIST_LOYALTIES = gql`
  query {
    listLoyalties {
      id
      name
      currency_value
      points
      owner_id
      created_at
      verification
      expiration
      status
      image_url
      tier {
        id
        name
        min_spending_target
        max_spending_target
        magnification
      }
    }
  }
`;

const CREATE_LOYALTY = gql`
  mutation CreateLoyalty(
    $name: String!
    $image_url: String!
    $currency_value: Int!
    $points: Int!
    $verification: Boolean
    $expiration: Int
  ) {
    createLoyalty(
      name: $name
      image_url: $image_url
      currency_value: $currency_value
      points: $points
      verification: $verification
      expiration: $expiration
    ) {
      id
      name
      image_url
      currency_value
      points
      verification
      expiration
    }
  }
`;

const EDIT_LOYALTY = gql`
  mutation EditLoyalty($id: ID!, $data: EditLoyaltyInput!) {
    editLoyalty(id: $id, data: $data) {
      id
      name
      image_url
      currency_value
      points
      verification
      expiration
    }
  }
`;

const EDIT_LOYALTY_CUSTOMER = gql`
  mutation EditCustomer($id: String, $data: EditCustomerInput!) {
    editCustomer(id: $id, data: $data) {
      id
      first_name
      last_name
      email
    }
  }
`;

const ACTIVATE_LOYALTY = gql`
  mutation ActivateLoyalty($id: ID!) {
    activateLoyalty(id: $id) {
      id
    }
  }
`;

const DEACTIVATE_LOYALTY = gql`
  mutation DeactivateLoyalty($id: ID!) {
    deActivateLoyalty(id: $id) {
      id
      name
      image_url
      currency_value
      status
    }
  }
`;

const DELETE_LOYALTY = gql`
  mutation DeleteLoyalty($id: ID!) {
    deleteLoyalty(id: $id) {
      status
      message
    }
  }
`;

const CREATE_LOYALTY_SUCCESS = "You Have Successfully created a New Loyalty";
const EDIT_LOYALTY_SUCCESS = "You Have Successfully Edited this Loyalty";
const DELETE_LOYALTY_SUCCESS = "You Have Successfully Deleted this Loyalty";

const GET_LOYALTY = gql`
  query GetLoyalty($id: ID!) {
    getLoyalty(id: $id) {
      id
      name
      currency_value
      points
      is_owner
      created_at
      tier {
        id
        name
        min_spending_target
        max_spending_target
        reward
        is_reward_self
        instruction
        created_at
      }
    }
  }
`;

const CREATE_TIER = gql`
  mutation CreateTier(
    $name: String!
    $min_spending_target: Int!
    $max_spending_target: Int!
    $is_reward_self: Boolean
    $reward: String
    $instruction: String
    $loyalty_id: ID!
    $partners_id: ID
    $magnification: Int
  ) {
    createTier(
      name: $name
      min_spending_target: $min_spending_target
      max_spending_target: $max_spending_target
      is_reward_self: $is_reward_self
      magnification: $magnification
      reward: $reward
      instruction: $instruction
      loyalty_id: $loyalty_id
      partners_id: $partners_id
    ) {
      id
      name
      min_spending_target
      max_spending_target
      is_reward_self
      range
      reward
      magnification
      created_at
      instruction
    }
  }
`;

const LIST_TIERS = gql`
  query listTiers($loyalty_id: ID!) {
    listTiers(loyalty_id: $loyalty_id) {
      id
      name
      min_spending_target
      max_spending_target
      is_reward_self
      reward
      instruction
      magnification
      range
      tierPosition
      created_at
      customers {
        id
        customer_id
        first_name
        last_name
        email
        points {
          id
          points_value
          expiry_date
          loyalty_name
          loyalty_id
        }
      }
    }
  }
`;

const DELETE_TIER = gql`
  mutation DeleteTier($id: ID!) {
    deleteTier(id: $id) {
      status
      message
    }
  }
`;

const EDIT_TIER = gql`
  mutation EditTier($id: ID!, $data: EditTierInput!) {
    editTier(id: $id, data: $data) {
      id
      name
      min_spending_target
      max_spending_target
      reward
      magnification
      range
      customers {
        id
      }
    }
  }
`;

const LIST_PARTNERS = gql`
  query ListPartners {
    listPartners {
      id
      company_name
      company_rep_name
      company_rep_email
      company_id
      banner
      company_rep_phone
      tier {
        id
        name
        min_spending_target
        max_spending_target
      }
    }
  }
`;

const LIST_FORMS = gql`
  query ListForms {
    listForms {
      id
      form_name
      merchant_id
      active
      recipient_emails
      form_fields {
        field_name
        field_input_type
        field_options
        required_field
        notes
      }
    }
  }
`;

const ADD_PARTNER = gql`
  mutation AddPartner($data: partnersInput) {
    addPartner(data: $data) {
      company_name
      company_rep_name
      company_rep_email
      company_rep_phone
      company_address_1
      company_address_2
      company_id
      postal_code
      partner_category
      banner
      state
      country
      status
      rewards {
        name
        type
        description
        quantity
      }
    }
  }
`;

const DELETE_PARTNER = gql`
  mutation DeletePartner($id: ID!) {
    deletePartner(id: $id) {
      status
      message
    }
  }
`;

const GET_PARTNER = gql`
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      company_name
      company_rep_email
      company_rep_phone
      tier {
        id
        name
        min_spending_target
        max_spending_target
      }
      rewards {
        id
        name
        type
        description
        quantity
        created_at
      }
    }
  }
`;

const ADD_PARTNER_TO_LOYALTY = gql`
  mutation AddPartnerToLoyalty($id: ID!, $partner_id: ID!) {
    addPartnerToLoyalty(id: $id, partner_id: $partner_id) {
      id
    }
  }
`;

const OPT_OUT_PARTNER_LOYALTY = gql`
  mutation OptOutPartnerLoyalty($loyalty_id: ID!, $merchant_id: ID!) {
    optOutPartnerLoyalty(loyalty_id: $loyalty_id, merchant_id: $merchant_id) {
      id
    }
  }
`;

const ADD_REWARD = gql`
  mutation AddReward(
    $name: String!
    $type: String
    $description: String
    $quantity: Int!
    $partner_id: ID!
  ) {
    addReward(
      name: $name
      type: $type
      description: $description
      quantity: $quantity
      partner_id: $partner_id
    ) {
      name
      type
      description
      quantity
      partner_id
    }
  }
`;

const ADD_FORM = gql`
  mutation AddForm(
    $form_name: String!
    $active: Boolean
    $form_fields: String!
    $recipient_emails: String
  ) {
    addForm(
      form_name: $form_name
      active: $active
      form_fields: $form_fields
      recipient_emails: $recipient_emails
    ) {
      id
      form_name
      merchant_id
      active
      recipient_emails
      form_fields {
        field_name
        field_input_type
        field_options
        required_field
        notes
      }
    }
  }
`;

const EDIT_FORM = gql`
  mutation EditForm($id: ID!, $data: EditFormInput!) {
    editForm(id: $id, data: $data) {
      id
      form_name
      merchant_id
      active
      recipient_emails
      form_fields {
        field_name
        field_input_type
        field_options
        required_field
        notes
      }
    }
  }
`;

const DELETE_FORM = gql`
  mutation DeleteForm($id: ID!) {
    deleteForm(id: $id) {
      id
      status
      message
    }
  }
`;

const SUBMIT_FORM_DATA = gql`
  mutation SubmitFormData($id: ID!, $formData: String!, $savedBy: String!) {
    submitFormData(id: $id, formData: $formData, savedBy: $savedBy) {
      id
      status
      message
    }
  }
`;

const LIST_GIFT_CARDS = gql`
  query ListGiftCards {
    listGiftCards {
      id
      card_number
      amount
      merchant_id
      customer_id
      isActive
      expiry_date
      sender_name
      recipient_name
      recipient_email
      recipient_phone_number
      secret_code
      gift_card_url
      created_at
    }
  }
`;

const GET_MERCHANT = gql`
  query GetMerchant {
    getMerchant {
      merchant_id
      gift_card_template_image_url
      gift_card_template_text_color
    }
  }
`;

const ADD_GIFT_CARDS = gql`
  mutation AddGiftCards($number: Int!) {
    addGiftCards(number: $number) {
      id
      card_number
      amount
      merchant_id
      customer_id
      expiry_date
      isActive
      created_at
    }
  }
`;

const EDIT_GIFT_CARD = gql`
  mutation EditGiftCard($data: GiftCardInput!) {
    editGiftCard(data: $data) {
      id
      card_number
      merchant_id
      amount
      customer_id
      expiry_date
      sender_name
      recipient_name
      recipient_email
      recipient_phone_number
      gift_card_url
      isActive
      created_at
    }
  }
`;

const SAVE_GIFT_CARD_TEMPLATE = gql`
  mutation SaveGiftCardTemplate($data: GiftCardTemplateInput!) {
    saveGiftCardTemplate(data: $data) {
      merchant_id
      gift_card_template_image_url
      gift_card_template_text_color
    }
  }
`;

const DELETE_GIFT_CARD = gql`
  mutation DeleteGiftCard($id: ID!) {
    deleteGiftCard(id: $id) {
      id
      status
      message
    }
  }
`;

const ENROLL_CUSTOMER_WITHOUT_POINTS = gql`
  mutation EnrollCustomerWithoutPoints(
    $loyalty_id: ID!
    $customerListId: [CustomerInput!]
  ) {
    enrollCustomerWithoutPoints(
      loyalty_id: $loyalty_id
      customerListId: $customerListId
    ) {
      id
      customer_id
      points {
        id
        loyalty_id
        expiry_date
        points_value
      }
      tiers {
        id
        name
        min_spending_target
        max_spending_target
      }
    }
  }
`;

const ENROLL_CUSTOMER_WITH_POINTS = gql`
  mutation EnrollCustomerWithPoints(
    $loyalty_id: ID!
    $customerListId: [CustomerInput!]
    $points: Int!
  ) {
    enrollCustomerWithPoints(
      loyalty_id: $loyalty_id
      customerListId: $customerListId
      points: $points
    ) {
      id
      customer_id
      points {
        points_value
        expiry_date
        loyalty_name
      }
      tiers {
        name
        min_spending_target
        max_spending_target
        reward
      }
    }
  }
`;

const ENROLL_CUSTOMER_WITH_AMOUNT_SPENT = gql`
  mutation EnrollCustomerWithAmountSpent(
    $loyalty_id: ID!
    $customer: CustomerInput!
    $amount_spent: Float!
  ) {
    enrollCustomerWithAmountSpent(
      loyalty_id: $loyalty_id
      customer: $customer
      amount_spent: $amount_spent
    ) {
      id
      customer_id
      points {
        points_value
        expiry_date
        loyalty_name
      }
      tiers {
        name
        min_spending_target
        max_spending_target
        reward
      }
    }
  }
`;

const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($id: String!) {
    deleteCustomer(id: $id) {
      id
      status
      message
    }
  }
`;

const LIST_CUSTOMERS = gql`
  query ListCustomers {
    listCustomers {
      id
      customer_id
      first_name
      last_name
      tiers {
        id
        name
        reward
        min_spending_target
        max_spending_target
      }
      points {
        id
        points_value
        expiry_date
        loyalty_name
      }
    }
  }
`;

const LIST_LOYALTY_CUSTOMERS = gql`
  query ListLoyaltyCustomers($loyalty_id: ID) {
    listLoyaltyCustomers(loyalty_id: $loyalty_id) {
      id
      customer_id
      first_name
      last_name
      email
      user_id
    }
  }
`;

const GET_CUSTOMER = gql`
  query GetCustomer($id: ID!) {
    customer: getCustomer(id: $id) {
      id
      customer_id
      first_name
      last_name
      email
      tiers {
        name
        min_spending_target
        max_spending_target
      }
      points {
        points_value
        amount
        expiry_date
        loyalty_id
        loyalty_name
      }
    }
  }
`;

const LIST_TIERED_CUSTOMERS = gql`
  query ListTieredCustomers {
    listTieredCustomers {
      id
      customer_id
      first_name
      last_name
      email
      # address
      created_at
      points {
        id
        points_value
        loyalty_id
        expiry_date
        loyalty_name
      }
      tiers {
        id
        name
        min_spending_target
        max_spending_target
        reward
        created_at
      }
    }
  }
`;

const LIST_UNTIERED_CUSTOMERS = gql`
  query ListUntieredCustomers {
    listUntieredCustomers {
      id
      customer_id
      first_name
      last_name
      email
      phone_number
      tiers {
        name
      }
      points {
        points_value
      }
      # address
      created_at
    }
  }
`;

const LIST_UNENROLLED_MEMBERS = gql`
  query ListUnenrolledMembers {
    listUnenrolledMembers {
      id
      customer_id
      first_name
      last_name
      email
      # address
      created_at
    }
  }
`;

const REDEEM_CUSTOMER_POINTS = gql`
  mutation RedeemCustomerPoints(
    $amount: Float!
    $loyalty_id: ID!
    $customer_id: Int!
  ) {
    redeemCustomerPoints(
      amount: $amount
      loyalty_id: $loyalty_id
      customer_id: $customer_id
    ) {
      id
      first_name
      last_name
      email
      tiers {
        name
      }
      points {
        points_value
        expiry_date
        loyalty_name
      }
    }
  }
`;

const GET_WORK_HOURS = gql`
  query GetWorkHours {
    getWorkHours {
      id
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
  }
`;

const GET_SERVICES = gql`
  query GetServices {
    getServices {
      id
      service_type
      service_name
      amount_charged
      completion_time
      loyalty_id
      created_at
      image_url
      duration
      online_booking
      description
      location
    }
  }
`;

const GET_BOOKINGS = gql`
  query GetBookings ($branch_id: Int){
    getBookings (branch_id: $branch_id){
      id
      service_name
      customer_id
      staff_name
      staff_id
      staff_email
      booking_date
      status
      payment_status
      amount_charged
      completion_time
      loyalty_id
      branch_name
      branch_id
      repeat
      repeat_frequency
      repeat_number
      service_id
      created_at
    }
  }
`;

const SET_WORK_HOURS = gql`
  mutation SetWorkHours($id: ID, $data: workHoursInput!) {
    setWorkHours(id: $id, data: $data) {
      id
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
  }
`;

const CREATE_SERVICE = gql`
  mutation CreateService($data: serviceInput!) {
    createService(data: $data) {
      id
      service_name
      service_type
      amount_charged
      completion_time
      loyalty_id
      created_at
      image_url
      duration
      online_booking
      description
      location
    }
  }
`;

const EDIT_SERVICE = gql`
  mutation EditService($id: ID, $data: serviceInput!) {
    editService(id: $id, data: $data) {
      id
      service_name
      service_type
      amount_charged
      completion_time
      loyalty_id
      created_at
      image_url
      duration
      online_booking
      description
      location
    }
  }
`;

const DELETE_SERVICE = gql`
  mutation DeleteService($id: ID!) {
    deleteService(id: $id) {
      id
      status
      message
    }
  }
`;

const CREATE_BOOKING = gql`
  mutation CreateBooking($data: appointmentInput!) {
    createBooking(data: $data) {
      service_name
      customer_id
      staff_name
      staff_id
      staff_email
      booking_date
      status
      payment_status
      amount_charged
      completion_time
      loyalty_id
      branch_name
      branch_id
      repeat
      repeat_frequency
      repeat_number
      service_id
      created_at
      id
    }
  }
`;

const EDIT_BOOKING = gql`
  mutation EditBooking($id: ID, $data: appointmentInput!) {
    editBooking(id: $id, data: $data) {
      service_name
      customer_id
      staff_name
      staff_id
      staff_email
      booking_date
      status
      payment_status
      amount_charged
      completion_time
      loyalty_id
      branch_name
      branch_id
      repeat
      repeat_frequency
      repeat_number
      service_id
      created_at
      id
    }
  }
`;

const DELETE_BOOKING = gql`
  mutation DeleteBooking($id: ID) {
    deleteBooking(id: $id) {
      id
      status
      message
    }
  }
`;

const COMPLETE_BOOKING = gql`
  mutation CompleteBooking($id: ID) {
    completeBooking(id: $id) {
      id
      status
      message
    }
  }
`;

const UPDATE_BOOKING_PAYMENT = gql`
  mutation UpdateBookingPayment($id: ID) {
    updateBookingPayment(id: $id) {
      id
      status
      message
    }
  }
`;

const GET_CATEGORIES = gql`
  query GetCategories {
    getCategories {
      id
      category_name
    }
  }
`;

const ADD_CATEGORY = gql`
  mutation AddCategory($category_name: String!) {
    addCategory(category_name: $category_name) {
      id
      category_name
    }
  }
`;

const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: ID) {
    deleteCategory(id: $id) {
      id
      status
      message
    }
  }
`;

const CREATE_TIER_SUCCESS = "You Have Successfully Created A New Tier";

const EDIT_TIER_SUCCESS = "You Have Successfully Edited this Tier";

const DELETE_TIER_SUCCESS = "You Have Successfully Deleted this Tier";

const DELETE_CUSTOMER_SUCCESS = "You Have Successfully Deleted this Customer";
const EDIT_CUSTOMER_SUCCESS = "You Have Successfully Edited this Customer";

const ENROLL_CUSTOMER_SUCCESS =
  "You Have Successfully Enrolled Customer(s) onto this Loyalty Programme";

const ADD_CUSTOMER_SUCCESS = "You Have Successfully added a New Customer";

const UREWARDS_SUBSCRIPTION = gql`
  subscription check_in {
    check_in {
      status
      message
      identifier_string
      customer_id
    }
  }
`;

const UPDATE_MERCHANT_UREWARD_ID = gql`
  query UpdateMerchantUrewardId {
    updateMerchantUrewardId {
      status
      message
    }
  }
`;

const USE_ORGANISATION_GIFTCARD = gql`
  query useVoucher($data: topUp) {
    useVoucher(data: $data) {
      message
      giftCard_balance
    }
  }
`;

const CONFIRM_ORGANISATION_GIFTCARD = gql`
  query confirmGiftCard($data: checkCustomer) {
    confirmGiftCard(data: $data) {
      message
      giftCard_balance
    }
  }
`;

const UPDATE_MERCHANT_SENDERID = gql`
  query updateMerchantSenderId($uRewardsId: String, $merchant_id: Int){
    updateMerchantSenderId(uRewardsId: $uRewardsId, merchant_id: $merchant_id){
      message,
      uRewardsId
    }
  }
`
const GET_MERCHANT_SENDERID = gql`
  query getMerchantSenderId($merchant_id: Int){
    getMerchantSenderId(merchant_id: $merchant_id){
      message,
      uRewardsId
    }
  }
`
export {
  UREWARDS_SUBSCRIPTION,
  UPDATE_MERCHANT_UREWARD_ID,
  LIST_LOYALTIES,
  CREATE_LOYALTY,
  EDIT_LOYALTY,
  EDIT_LOYALTY_CUSTOMER,
  ACTIVATE_LOYALTY,
  DEACTIVATE_LOYALTY,
  DELETE_LOYALTY,
  CREATE_LOYALTY_SUCCESS,
  EDIT_LOYALTY_SUCCESS,
  EDIT_CUSTOMER_SUCCESS,
  DELETE_LOYALTY_SUCCESS,
  GET_LOYALTY,
  CREATE_TIER,
  LIST_PARTNERS,
  REDEEM_CUSTOMER_POINTS,
  GET_PARTNER,
  ADD_PARTNER,
  DELETE_PARTNER,
  DELETE_CUSTOMER,
  ADD_PARTNER_TO_LOYALTY,
  ADD_REWARD,
  LIST_TIERS,
  DELETE_TIER,
  EDIT_TIER,
  LIST_CUSTOMERS,
  GET_CUSTOMER,
  LIST_LOYALTY_CUSTOMERS,
  LIST_TIERED_CUSTOMERS,
  LIST_UNTIERED_CUSTOMERS,
  LIST_UNENROLLED_MEMBERS,
  ENROLL_CUSTOMER_WITH_POINTS,
  ENROLL_CUSTOMER_WITHOUT_POINTS,
  OPT_OUT_PARTNER_LOYALTY,
  ENROLL_CUSTOMER_WITH_AMOUNT_SPENT,
  CREATE_TIER_SUCCESS,
  EDIT_TIER_SUCCESS,
  DELETE_TIER_SUCCESS,
  DELETE_CUSTOMER_SUCCESS,
  ENROLL_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_SUCCESS,
  ADD_FORM,
  EDIT_FORM,
  DELETE_FORM,
  SUBMIT_FORM_DATA,
  LIST_FORMS,
  GET_WORK_HOURS,
  GET_BOOKINGS,
  GET_SERVICES,
  SET_WORK_HOURS,
  CREATE_BOOKING,
  CREATE_SERVICE,
  EDIT_BOOKING,
  EDIT_SERVICE,
  DELETE_BOOKING,
  DELETE_SERVICE,
  COMPLETE_BOOKING,
  UPDATE_BOOKING_PAYMENT,
  GET_CATEGORIES,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  LIST_GIFT_CARDS,
  ADD_GIFT_CARDS,
  SAVE_GIFT_CARD_TEMPLATE,
  GET_MERCHANT,
  EDIT_GIFT_CARD,
  DELETE_GIFT_CARD,
  USE_ORGANISATION_GIFTCARD,
  CONFIRM_ORGANISATION_GIFTCARD,
  UPDATE_MERCHANT_SENDERID,
  GET_MERCHANT_SENDERID
};
