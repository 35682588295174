import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getAllExpiredProducts, getAllExpringProducts } from '../../../../../../redux/actions/saleActions';
import { Modal, Select, message } from 'antd';
import CustomTable from '../../../../../../components/CustomDataTable';
import strings from '../../../../../../strings';
import moment from 'moment';

const ExpiredItems = ({
    open, 
    onCancel,
    getAllExpiredProducts,
    getAllExpringProducts,
    user,
}) => {
    const [ pagination, setPagination ] = useState({
        total: 0,
        pageSize: 10,
        pageNumber: 1,
        defaultPageSize:10
    });
    const [ loading, setLoading ] = useState(false);
    const [ expiredItems, seExpiredItems ] = useState([]);
    const [ noOfDays, setNoOfDays ] = useState(null);


    useEffect(() => {
        handleGetExpiredItems(noOfDays, pagination.pageNumber, pagination.pageSize)
    }, []);

     const columns = [
            {
              title: strings.productName,
              dataIndex: "name",
              render: (record) =>
                record ? <span>{record}</span> : <span>N/A</span>,
            },
            {
              title: strings.price,
              dataIndex: "price",
              render: (record, value) => (
                record ? <span>{user.currency} {record}</span> : <span>N/A</span>
              )
            },
            {
              title: strings.quantity,
              dataIndex: "quantity",
              render: (record) => (record ? <span>{record}</span> : <span>0</span>),
            },
            // {
            //   title: strings.returnedQuantity,
            //   dataIndex: "return_quantity",
            //   render: (record) => (record ? <span>{record}</span> : <span>N/A</span>),
            // },
            {
                title: strings.createdDate,
                dataIndex: "created_at",
                render: (record, item) => (
                    record ? <span>{moment(record).format("DD MMMM YYYY")}</span> : <span>N/A</span>
                ),
            },
            {
                title: strings.expiryDate,
                dataIndex: "expiry_date",
                render: (record, item) => (
                    record ? <span>{moment(record).format("DD MMMM YYYY h:mm:A")}</span> : <span>N/A</span>
                ),
            },
    ];


    const handleGetExpiredItems = (days, pageNumber, pageSize) => {
        getAllExpiredProducts(days, pageNumber, pageSize)
        .then(res => {
            if(res.status === 200){
                setPagination((prev) => {
                    return {
                        ...prev,
                        total: res.headers["total"],
                        pageNumber: pageNumber
                    }
                })
                seExpiredItems(res.data.sort((a, b) => b.expiry_date.localeCompare(a.expiry_date)));
                setLoading(false);
            }
        })
        .catch(err => {
            setLoading(false);
            console.log("Err", err);
            message.error("Unable to return all expired items");
        })
    }

    const handleGetExpiringItems = (days, pageNumber, pageSize) => {
        getAllExpringProducts(days, pageNumber, pageSize)
        .then(res => {
            if(res.status === 200){
                setPagination((prev) => {
                    return {
                        ...prev,
                        total: res.headers["total"],
                        pageNumber: pageNumber
                    }
                })
                seExpiredItems(res.data.sort((a, b) => b.expiry_date.localeCompare(a.expiry_date)));
                setLoading(false);
            }
        })
        .catch(err => {
            setLoading(false);
            console.log("Err", err);
            message.error("Unable to return all expired items");
        })
    }

    const handleDayChange = (value) => {
        setNoOfDays(value);
        if(value === strings.reset) return handleGetExpiredItems(null, pagination.pageNumber, pagination.pageSize);
        handleGetExpiringItems(value, pagination.pageNumber, pagination.pageSize);
    }
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title={`${strings.expiredItems} ${pagination.total ? `(${pagination.total})` : ""}`}
        footer={null}
        width={600}
    >
        <div style={{display: "flex", alignItems: "center", justifyContent: "end"}}>
            <Select placeholder={strings.selectExpringNoOfDays} onChange={(value) => handleDayChange(value)}>
                <Select.Option value={strings.reset}>{strings.reset}</Select.Option>
                <Select.Option value={7}>1 Week</Select.Option>
                <Select.Option value={14}>2 Weeks</Select.Option>
                <Select.Option value={30}>1 month</Select.Option>
                <Select.Option value={60}>3 month</Select.Option>
                <Select.Option value={120}>6 month</Select.Option>
            </Select>
        </div>
        <CustomTable 
            columns={columns}
            data={expiredItems}
            pagination={pagination}
            current={pagination.pageNumber}
            loading={loading}
            total={pagination.total}
            dataSource={expiredItems}
            onChange={(page, rest) => {
                const { pageSize, defaultPageSize, current } = page;
                handleGetExpiredItems(noOfDays, current, pageSize)
            }}
        />
    </Modal>
  )
}


const mapStateToProps = (state) => ({
    user: state.auth.user
});

export default connect(mapStateToProps, { getAllExpiredProducts, getAllExpringProducts })(ExpiredItems)