import React, { forwardRef } from 'react'
import strings from '../../../../../strings';
import "./index.scss";

const Receipt = forwardRef(({ supplierDetails, user }, ref)=>{
   return (
          <div ref={ref}>
              <div className="orderprint">
                  <h2 className='padding-left padding-right'>Supply Receipt</h2>
                  <div>
                      <div className="orderprintitems contact-info padding-left padding-right">
                          <h3>{user.employer ? user.employer.business_name : user.business_name}</h3>
                      </div>
                      <div className="orderprintitems contact-info padding-left padding-right" style={{marginLeft: 30}}>
                            {user.image && (
                                <img src={user.image} style={{ width: 100, height: 100 }} alt="merchant logo" />
                            )}
                            {user.employer && user.employer.image && (
                                <img src={user.employer.image} style={{ width: 100, height: 100 }} alt="merchant logo" />
                            )}
                        </div>
                      <div className="orderprintitems contact-info padding-left padding-right">
                          <h3>{strings.supplier}:</h3>
                          <p>
                              {supplierDetails && supplierDetails.supplier} 
                          </p>
                      </div>
                      <div className="orderprintitems contact-info padding-left padding-right">
                          <h3>{strings.receivedBy}:</h3>
                          <p>
                           {supplierDetails && supplierDetails.received_by}
                          </p>
                      </div>
                      <div className="orderprintitems contact-info padding-left padding-right">
                          <h3>{strings.quantity}:</h3>
                          <p>
                              {supplierDetails && supplierDetails.quantity}
                          </p>
                      </div>
                      <div className="orderprintitems contact-info padding-left padding-right">
                          <h3>{strings.unitPrice}:</h3>
                          <p>
                              {supplierDetails && supplierDetails.unit_price}
                          </p>
                      </div>
                      <div className="orderprintitems contact-info padding-left padding-right">
                          <h3>{strings.product}</h3>
                          <p>
                              {supplierDetails && supplierDetails.product.name}
                          </p>
                      </div>
                   {/* <h3 className='orderprintitems contact-info padding-left padding-right'>Item(s) Purchased</h3> */}
                      <div className="footer loyalty-balance">
                          {/* <p className="">{strings.callCashierToCompletePayment}.</p> */}
                          <p>---</p>
                          <p className="smallfont">POWERED BY LOYSTAR ☺</p>
                          <span className="smallfont">WWW.LOYSTAR.CO</span>
                      </div>
                  </div>
              </div>
          </div>
      )
})

export default Receipt