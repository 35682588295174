export default class Sales {
  constructor(client) {
    this.client = client;
  }

  recordSale(saleData) {
    return this.client.post("/sales", saleData);
  }//payment_type=is_paid_with_card

  getAllSales(page, type = "default", size, paymentType) {
    const pageNuumber = page || 1;
    const pageSize = size || 30;

    if (type === "default") {
      if(paymentType){
        return this.client.get(
          `/sales_list?page[number]=${pageNuumber}&page[size]=${pageSize}&payment_type=${paymentType}`
        );
      }else{
        return this.client.get(
          `/sales_list?page[number]=${pageNuumber}&page[size]=${pageSize}`
        );
      }
    }

    if (type === "export") {
      return this.client.get(`/sales_list?page[size]=100000`);
    }
  }

  getMerchantRedemptionLoyalty(pageNumber=1, pageSize=100, from, to){
    if(!from && !to) return this.client.get(`/merchant/transactions/simple_loyalty_redemption?page[number]=${pageNumber}&page[size]=${pageSize}`);
    return this.client.get(`/merchant/transactions/simple_loyalty_redemption?page[number]=${pageNumber}&page[size]=${pageSize}to=${to}&from=${from}`);
  }

  getCustomerRedemptionLoyalty(user_id){
    return this.client.get(`/merchant/transactions/simple_loyalty_redemption?customer_id=${user_id}`);
  }

  sendSaleReportToEmail(from, to){//from, to'/api/v2/
    if(from && to)  return this.client.get(
      `/merchant/send_sales_report?to=${to}&from=${from}`
    );

    return this.client.get(
      `/merchant/send_sales_report`
    );
  }

  getBranchSales(params, staff_id, paymentType, customer_id, transaction_id, allowVat){
    const pageNumber = params.current || 1;
    const pageSize = params.size || 30;

    console.log("page titl", params);
    
    if (params.type === "default") {
      if(allowVat){
        if(staff_id){
          if(paymentType && customer_id && transaction_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}&customer_id=${customer_id}&sale_id=${transaction_id}&vat_search=true`
            );
          }else if(paymentType && customer_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}&vat_search=true`
            );
          }else if(paymentType){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}&vat_search=true`
            );
          }else if(customer_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}&customer_id=${customer_id}&vat_search=true`
            );
          }else if(transaction_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}&sale_id=${transaction_id}&vat_search=true`
            );
          }else{
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}&vat_search=true`
            );
          }
        }else{
          if(paymentType && customer_id && transaction_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}&sale_id=${transaction_id}&payment_type=${paymentType}&customer_id=${customer_id}&vat_search=true`
            );
          }else if(paymentType && customer_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}&customer_id=${customer_id}&vat_search=true`
            );
          }else if(paymentType){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}&vat_search=true`
            );
          }else if(customer_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}&customer_id=${customer_id}&vat_search=true`
            );
          }else if(transaction_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}&sale_id=${transaction_id}&vat_search=true`
            );
          }else{
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}&vat_search=true`
            );
          }
        }
      }else {
        if(staff_id){
          if(paymentType && customer_id && transaction_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}&customer_id=${customer_id}&sale_id=${transaction_id}`
            );
          }else if(paymentType && customer_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}`
            );
          }else if(paymentType){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}`
            );
          }else if(customer_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}&customer_id=${customer_id}`
            );
          }else if(transaction_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}&sale_id=${transaction_id}`
            );
          }else{
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}`
            );
          }
        }else{
          if(paymentType && customer_id && transaction_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}&sale_id=${transaction_id}&payment_type=${paymentType}&customer_id=${customer_id}`
            );
          }else if(paymentType && customer_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}&customer_id=${customer_id}`
            );
          }else if(paymentType){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}`
            );
          }else if(customer_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}&customer_id=${customer_id}`
            );
          }else if(transaction_id){
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}&sale_id=${transaction_id}`
            );
          }else{
            return this.client.get(
              `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}`
            );
          }
        }
      }
    }

    if (params.type === "export") {
      return this.client.get(`/sales/business_branch_sales/${params.id}?page[size]=100000`);
    }
  }

  getBranchSalesByDuration(from, to, id, pageNumber = 1, type = "default", paymentType=null, staff_id=null,transaction_id=null,allowVat){
    if (type === "default") {
      if(allowVat){
        if(paymentType && staff_id && transaction_id){
          return this.client.get(
            `/sales/business_branch_sales/${id}?to=${to}&from=${from}&staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=30&payment_type=${paymentType}&sale_id=${transaction_id}&vat_search=true&vat_search=true`
          );
        }
  
        if(paymentType && staff_id){
          return this.client.get(
            `/sales/business_branch_sales/${id}?to=${to}&from=${from}&staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=30&payment_type=${paymentType}&vat_search=true`
          );
        }
  
        if(staff_id){
          return this.client.get(
            `/sales/business_branch_sales/${id}?to=${to}&from=${from}&page[number]=${pageNumber}&page[size]=30&staff_id=${staff_id}&vat_search=true`
          );
        }
  
        if(paymentType){
          return this.client.get(
            `/sales/business_branch_sales/${id}?to=${to}&from=${from}&page[number]=${pageNumber}&page[size]=30&payment_type=${paymentType}&vat_search=true`
          );
        }
  
        if(transaction_id){
          return this.client.get(
            `/sales/business_branch_sales/${id}?to=${to}&from=${from}&page[number]=${pageNumber}&page[size]=30&sale_id=${transaction_id}&vat_search=true`
          );
        }
  
        
        return this.client.get(
          `/sales/business_branch_sales/${id}?to=${to}&from=${from}&page[number]=${pageNumber}&page[size]=30&vat_search=true`
        )
      }else{
        if(paymentType && staff_id && transaction_id){
          return this.client.get(
            `/sales/business_branch_sales/${id}?to=${to}&from=${from}&staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=30&payment_type=${paymentType}&sale_id=${transaction_id}`
          );
        }
  
        if(paymentType && staff_id){
          return this.client.get(
            `/sales/business_branch_sales/${id}?to=${to}&from=${from}&staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=30&payment_type=${paymentType}`
          );
        }
  
        if(staff_id){
          return this.client.get(
            `/sales/business_branch_sales/${id}?to=${to}&from=${from}&page[number]=${pageNumber}&page[size]=30&staff_id=${staff_id}`
          );
        }
  
        if(paymentType){
          return this.client.get(
            `/sales/business_branch_sales/${id}?to=${to}&from=${from}&page[number]=${pageNumber}&page[size]=30&payment_type=${paymentType}`
          );
        }
  
        if(transaction_id){
          return this.client.get(
            `/sales/business_branch_sales/${id}?to=${to}&from=${from}&page[number]=${pageNumber}&page[size]=30&sale_id=${transaction_id}`
          );
        }
  
        
        return this.client.get(
          `/sales/business_branch_sales/${id}?to=${to}&from=${from}&page[number]=${pageNumber}&page[size]=30`
        )
      }
    }

    if (type === "export") {
      return this.client.get(
        `/sales/business_branch_sales/${id}?to=${to}&from=${from}&page[size]=100000`
      );
    }
  }

  getSalesForCashier(staff_id, branch_id, number, from, to, size, paymentType){
    const pageNumber = number || 1;
    const pageSize = size || 30;
    if(from && to)  {
      if(paymentType){
        return this.client.get(
          `/sales/business_branch_sales/${staff_id}/${branch_id}?to=${to}&from=${from}&page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}`
        );
      }else{
        return this.client.get(
          `/sales/business_branch_sales/${staff_id}/${branch_id}?to=${to}&from=${from}&page[number]=${pageNumber}&page[size]=${pageSize}`
        ); 
      }
    }

    if(paymentType){
      return this.client.get(
        `/sales/business_branch_sales/${staff_id}/${branch_id}?page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}`
      );
    }else{
      return this.client.get(
        `/sales/business_branch_sales/${staff_id}/${branch_id}?page[number]=${pageNumber}&page[size]=${pageSize}`
      );
    }

    
  }

  getSalesByPaymentType(branch_id, payment_type, pageNumber=1, pageSize=30){
    return this.client.get(
      `/sales/payment_type/${branch_id}?payment_type=${payment_type}&page[number]=${pageNumber}&page[size]=${pageSize}`
    );//api/v2/sales/customer_name/:business_branch_id?name=tang
  }

  getSalesByCustomerName(branch_id, name, pageNumber=1, pageSize=30){
    return this.client.get(
      `/sales/customer_name/${branch_id}?name=${name}&page[number]=${pageNumber}&page[size]=${pageSize}`
    );
  }///sales/customer/:business_branch_id?customer_id=234

  getSalesByCustomerId(branch_id, customer_id, pageNumber=1, pageSize=30){
    return this.client.get(
      `/sales/customer/${branch_id}?customer_id=${customer_id}&page[number]=${pageNumber}&page[size]=${pageSize}`
    );
  }

  getAllSalesByDuration(from, to, pageNuumber = 1, type = "default", paymentType=null) {
    if (type === "default") {
      if(paymentType){
        return this.client.get(
          `/sales_list?to=${to}&from=${from}&page[number]=${pageNuumber}&page[size]=30&payment_type=${paymentType}`
        );
      }

      return this.client.get(
        `/sales_list?to=${to}&from=${from}&page[number]=${pageNuumber}&page[size]=30`
      );
    }

    if (type === "export") {
      if(paymentType){
        return this.client.get(
          `/sales_list?to=${to}&from=${from}&page[size]=100000&payment_type=${paymentType}`
        );
      }
      return this.client.get(
        `/sales_list?to=${to}&from=${from}&page[size]=100000`
      );
    }
  }

  deleteSalesTransaction(salesId) {
    return this.client.post(`/sales/set_delete_flag_to_true/${salesId}`);
  }

  sendReceiptToEmail(data) {
    return this.client.post("/transaction/send_receipt", {
      data,
    });
  }

  getAllReturnItems(pageNumber=1, pageSize=10) {
    return this.client.get(`/sales_return/returned_inventory_items?page[number]=${pageNumber}&page[size]=${pageSize}`);
  }

  
  getAllDamagedItems(pageNumber=1, pageSize=10) {
    return this.client.get(`/sales_return/returned_damage_items?page[number]=${pageNumber}&page[size]=${pageSize}`);
  }

  getAllExpiredItems(noOfDays, pageNumber=1, pageSize=10) {
    if(noOfDays){
      return this.client.get(`/merchants/expired_products?days_to_expire=${noOfDays}&page[number]=${pageNumber}&page[size]=${pageSize}`);
    }
    return this.client.get(`/merchants/expired_products?page[number]=${pageNumber}&page[size]=${pageSize}`);
  }

  getAllExpiringItems(noOfDays, pageNumber=1, pageSize=10) {
    return this.client.get(`/merchants/expiring_products?days_to_expire=${noOfDays}&page[number]=${pageNumber}&page[size]=${pageSize}`);
  }

  returnOneITem(transaction_id) {
    return this.client.patch(`/sales_return/${transaction_id}/return_item`, {});
  }

  manageReturnedItem(transaction_id, data) {
    return this.client.patch(`/sales_return/${transaction_id}/manage_returned_item`, {
      data,
    });
  } 

  createPaymentLabels(data) {
    return this.client.post(`/payment_labels`, {
      payment_label: data
    });
  } 

  updatePaymentLabels(id, data) {
    return this.client.patch(`/payment_labels/${id}`, {
      payment_label: data
    });
  } 

   getPaymentLabels(id) {
    return this.client.get(`/payment_labels/${id}`);
  } 

   getAllPaymentLabels() {
    return this.client.get(`/payment_labels`);
  } 

   deletePaymentLabels(id) {
    return this.client.delete(`/payment_labels/${id}`);
  } 
}
