import {
  SHOW_VARIANT_MODAL,
  CLEAR_SHOW_VARIANT_MODAL_DATA,
  HAS_RECORDED_SALE,
  GET_SALES_DETAILS,
  GET_ALL_SALES,
  RECORD_DRAFT_BILL,
  SHOW_CUSTOMQTY_MODAL,
  CLEAR_SHOW_CUSTOMQTY_MODAL_DATA,
  SPLIT_PAYMENT_ACTIVE
} from "../constants";
import api from "../../api";
import Axios from "axios";

// show a varianted product with enter key
export const showVariantModal = (product) => (dispatch) => {
  return dispatch({
    type: SHOW_VARIANT_MODAL,
    payload: product,
  });
};

// show a varianted product with enter key
export const showCustomQtyModal = (product) => (dispatch) => {
  // console.log(product);
  return dispatch({
    type: SHOW_CUSTOMQTY_MODAL,
    payload: product,
  });
};

// show a varianted product with enter key
export const clearShowVariantModalData = () => (dispatch) => {
  return dispatch({
    type: CLEAR_SHOW_VARIANT_MODAL_DATA,
    payload: {},
  });
};

export const clearShowCustomQtyModalData = () => (dispatch) => {
  return dispatch({
    type: CLEAR_SHOW_CUSTOMQTY_MODAL_DATA,
    payload: {},
  });
};

// Tells if Merchant recorded any sale
export const merchantHasSale = (value) => (dispatch) => {
  return dispatch({
    type: HAS_RECORDED_SALE,
    payload: value,
  });
};



// Record Sale

export const recordSale =
  (
    transactionInfo,
    cartItems,
    addLoyaltyProgram,
    shared_loyalty_txn,
    // payment_reference,
    // reference_code
  ) =>
  async (dispatch) => {
    const {
      isPayPaidWithCash,
      isPayPaidWithCard,
      isPayPaidWithMobile,
      isPayPaidWithMTransfer,
      isPayPaidWithPoint,
      is_paid_with_customer_account,
      businessBranchId,
      card_payment_ref,
      userId,
      payment_reference,
      reference_code,
      has_discount,
      discount_amount,
      mtier_amount,
      loyalty_id,
      created_at,
      merchant_id,
      payment_label_id
    } = transactionInfo;

    //access the loyalty programs
    const loyaltyPrograms = JSON.parse(localStorage.getItem("loyaltyPrograms"));

    const activeInstantRewardLoyalty = JSON.parse(
      localStorage.getItem("activeInstantRewardLoyalty")
    );

    //check if a usr is attached to the transaction
    const addLoyaltyProgramToTransaction =
      userId && addLoyaltyProgram ? true : false;

    //filter out the loyalty program with the merchant_loyalty_program_id
    if (addLoyaltyProgramToTransaction) {
      cartItems.forEach((item) => {
        let loyaltyInfo = loyaltyPrograms.filter(
          (loyaltyProgram) =>
            item.merchant_loyalty_program_id === loyaltyProgram.id
        );
        item.program_type =
          loyaltyInfo.length > 0 ? loyaltyInfo[0].program_type : null;

        if (activeInstantRewardLoyalty) {
          item.program_type = activeInstantRewardLoyalty.program_type;
          item.merchant_loyalty_program_id = activeInstantRewardLoyalty.id;
        }
      });
    }

    // const itemsWithVariants = cartItems.filter((items)=>{
    //   return items.variants.length > 0
    // })
    // const itemsWithoutVAriants = cartItems.filter((items)=>{
    //   return items.variants.length === 0
    // })

    // const variantsItemsMoreThanOne = itemsWithVariants.filter((items)=>{
    //   return items
    // })

    
    // console.log("item", itemsWithVariants, itemsWithoutVAriants);
    // const cartitems = cartItems.map((item)=>{
    //   if(item.variants.length > 1){
    //     return item.variants.map((variant)=>{
    //       return {...item, variants: variant, quantity: variant.selectQty}
    //     })
    //   }else{
    //     return item
    //   }
    // })
  
    // const nenw = [...cartitems]

    const payload = {
      sale: {
        is_paid_with_cash: isPayPaidWithCash,
        is_paid_with_card: isPayPaidWithCard,
        is_paid_with_mobile: isPayPaidWithMobile,
        is_paid_with_mtransfer: isPayPaidWithMTransfer,
        is_paid_with_point: isPayPaidWithPoint,
        is_paid_with_customer_account,
        user_id: userId,
        business_branch_id: businessBranchId,
        transactions: cartItems,
        payment_reference: payment_reference || null,
        reference_code,
        card_payment_ref: card_payment_ref || null,
        has_discount: has_discount || false,
        shared_loyalty_txn,
        discount_amount: discount_amount || null,
        mtier_amount: mtier_amount || null, 
        loyalty_id: loyalty_id || null,
        created_at,
        merchant_id,
        payment_label_id
      },
    };

    console.log(payload);
    // console.log(payload, cartitems, cartItems, nenw);
    const res = await api.sales.recordSale(payload);
    if (res && res.status === 200) {
      dispatch({
        type: GET_SALES_DETAILS,
        payload: res.data,
      });
    }
    return Promise.resolve(res);
  };

const makeSale = async (saleData) => {
  try {
    const res = await fetch("https://api-stage.loystar.co/api/v2/sales", {
      method: "POST",
      headers: {
        "content-type": api.HttpClient.defaults.headers.common["Content-Type"],
        uid: api.HttpClient.defaults.headers.common["uid"],
        "access-token": api.HttpClient.defaults.headers.common["access-token"],
        client: api.HttpClient.defaults.headers.common["client"],
        "user-auth": api.HttpClient.defaults.headers.common["user-auth"],
      },
      body: JSON.stringify(saleData),
    });

    const data = await res.json();

    console.log(data);

    return data;
  } catch (error) {
    console.log(error); 
  }
};

export const getAllSales = (params, selectedPaymentType) => (dispatch) => {
  // const { current, type, pageSize } = params;
  // console.log("pageSize", current, type, pageSize);
  if(!params){
    return api.sales.getAllSales();
  }else{
    const { current, type, pageSize } = params;
    return api.sales.getAllSales(params.current, params.type, params.pageSize, selectedPaymentType);
  }
  
};

export const getAllMerchantLoyalty = (pageNumber, pageSize, from, to) => (dispatch) => {
  return api.sales.getMerchantRedemptionLoyalty(pageNumber, pageSize, from, to);
};

export const getCustomersLoyalty = (user_id) => (dispatch) => {
  return api.sales.getCustomerRedemptionLoyalty(user_id);
};

export const createPaymentLabel = (data) => (dispatch) => {
  return api.sales.createPaymentLabels(data);
};

export const updatePaymentLabel = (id, data) => (dispatch) => {
  return api.sales.updatePaymentLabels(id, data);
};

export const getPaymentLabel = (id) => (dispatch) => {
  return api.sales.getPaymentLabels(id);
};

export const getAllPaymentLabel = () => (dispatch) => {
  return api.sales.getAllPaymentLabels();
};

export const deletePaymentLabel = (id) => (dispatch) => {
  return api.sales.deletePaymentLabels(id);
};

export const getBranchSales = ( params, staff_id, selectedPaymentType, customer_id, transaction_id,allowVat, begin, end ) => ( dispatch ) => {
  return api.sales.getBranchSales(params, staff_id, selectedPaymentType, customer_id, transaction_id, allowVat, begin, end);
}

export const getSalesByPaymentType = (branch_id, selectedPaymentType, pageNumber, pageSize) => ( dispatch ) => {
  return api.sales.getSalesByPaymentType(branch_id, selectedPaymentType, pageNumber, pageSize);
}

export const getSalesByCustomerName = (branch_id, name, pageNumber, pageSize) => ( dispatch ) => {
  return api.sales.getSalesByCustomerName(branch_id, name, pageNumber, pageSize);
}//getSalesByCustomerId

export const getSalesByCustomerId = (branch_id, customer_id, pageNumber, pageSize) => ( dispatch ) => {
  return api.sales.getSalesByCustomerId(branch_id, customer_id, pageNumber, pageSize);
}

export const sendSalesReportToEmail = (from, to) => (dispatch) => {
  return api.sales.sendSaleReportToEmail(from, to);
}

export const getCashierSales = ( staff_id, branch_id, number, from, to, size, paymentType ) => ( dispatch ) => {
  // console.log("from1", from, to);
  return api.sales.getSalesForCashier(staff_id, branch_id, number, from, to, size, paymentType);
}

export const getAllSavedSales = (pageNumber, type) => async (dispatch) => {
  const res = await api.sales.getAllSales(pageNumber, type);
  if (res && res.status === 200) {
    dispatch({
      type: GET_ALL_SALES,
      payload: res.data,
    });
  }
  return Promise.resolve(res);
};

export const getBranchSalesByDuration =
  (from, to, id, pageNumber, type, paymentType, staff_id, transaction_id,allowVat) => (dispatch) => {
    return api.sales.getBranchSalesByDuration(from, to, id, pageNumber, type, paymentType, staff_id, transaction_id,allowVat);
  };

export const getAllSalesByDuration =
  (from, to, pageNumber, type, paymentType) => (dispatch) => {
    return api.sales.getAllSalesByDuration(from, to, pageNumber, type, paymentType);
  };

export const deleteSalesTransaction = (salesId) => (dispatch) => {
  return api.sales.deleteSalesTransaction(salesId);
};

export const getAllReturns = (pageNumber, pageSize) => (dispatch) => {
  return api.sales.getAllReturnItems(pageNumber, pageSize);
};

export const getAllDamaged = (pageNumber, pageSize) => (dispatch) => {
  return api.sales.getAllDamagedItems(pageNumber, pageSize);
};

export const getAllDamagedProducts = (pageNumber, pageSize) => (dispatch) => {
  return api.sales.getAllDamagedItems(pageNumber, pageSize);
};

export const getAllExpiredProducts = (noOfDays, pageNumber, pageSize) => (dispatch) => {
  return api.sales.getAllExpiredItems(noOfDays, pageNumber, pageSize);
};

export const getAllExpringProducts = (noOfDays, pageNumber, pageSize) => (dispatch) => {
  return api.sales.getAllExpiringItems(noOfDays, pageNumber, pageSize);
};

export const returnOneItem = (transaction_id) => (dispatch) => {
  return api.sales.returnOneITem(transaction_id);
};

export const mangeReturnedItems = (transaction_id, data) => (dispatch) => {
  return api.sales.manageReturnedItem(transaction_id, data);
};


//SPLIT PAYMENT
export const activeSplitPayment = ( value ) => (dispatch) => {
  dispatch({ type: SPLIT_PAYMENT_ACTIVE, payload: value })
}

////delviery with logistics
const deliveryUrl = process.env.REACT_APP_LOGISTIC_DELIVERY_HUB;
const api_key = process.env.REACT_APP_LOGISTIC_HUB_KEY;
const merchant_id = process.env.REACT_APP_LOGISTIC_MERCHANT_ID;
const nonitimUrl = process.env.REACT_APP_NOMINATIM_URL;
const geocodeUrl = process.env.REACT_APP_GEOCODE_URL;
const fleetTypeID = process.env.REACT_APP_FLEET_ID_BIKES

export const sendDelivery = async (data) => {
  const url = `${deliveryUrl}/integration/newDelivery`;
  const response = await Axios.post(url, data, {
      headers: {
          "x-lh-api-key": api_key,
          "x-lh-merchant-id": merchant_id,
      }
  });

  return Promise.resolve(response);
}

export const fetchDeliveryPrice = async (details) => {
  const latitude = details.latitude;
  const longitude = details.longitude;
  const pickupLatitude = details.pickupLatitude;
  const pickupLongitude = details.pickupLongitude;
  const response = await Axios.get(`${deliveryUrl}/integration/rate?fleetTypeId=${fleetTypeID}&fleetType=Bikes&destinationLatitude=${latitude}&destinationLongitude=${longitude}&pickupLongitude=${pickupLongitude}&pickupLatitude=${pickupLatitude}`, {
      headers: {
          "x-lh-api-key": api_key,
          "x-lh-merchant-id": merchant_id,
      }
  });

  return Promise.resolve(response);
  
}
