import React from "react";
import { Card, Button, Popover, message, Modal } from "antd";
import CustomDataTable from "../../../../components/CustomDataTable";
import AddNewStaffModal from "./_partials/AddNewStaffModal";
import PropTypes from "prop-types";
import { PlusOutlined } from "@ant-design/icons";

// Redux
import { connect } from "react-redux";
import {
  addNewBusinessBranch,
  getAllBusinessBranches,
  deleteStaff,
  archiveBranch,
  archiveStaff
} from "../../../../redux/actions/branchActions";
import { getPricingPlanData, getMerchantSubPlan } from "../../../../redux/actions/merchantAction";
import AddNewBranchModal from "./_partials/AddNewBranchModal";
import EditStaffModal from "./_partials/EditStaffModal";
import StaffTable from "./_partials/StaffTable";
import ChangeStaffPasswordModal from "../Account/_partials/ChangeStaffPasswordModal";
import strings from "../../../../strings";
import PaymentModal from "./_partials/paymentModal";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import EditBranchModal from "./_partials/EditBranchModal";

const toastMessageDuration = 5; //sec

class Branches extends React.Component {
  state = {
    isAddNewStaffModalOpen: false,
    isAddNewBranchModalOpen: false,
    isEditStaffModalOpen: false,
    isEditStaffPasswordModalOpen: false,
    branchPrice: null,
    staffPrice: null,
    ecommercePrice: null,
    activeBranch: null,
    loading: false,
    isDeletingStaff: false,
    currentStaff: null,
    currentBranchId: null,
    openPaymentModal: true,
    priceToPay: null,
    staffPaidFor: null,
    branchPaidFor: null,
    allStaff: null,
    openArchiveModal: false,
    branchToArchive: null,
    deleteLoading: false,
    selectedBranch: null,
    openEditBranchModal: false,
    expandedRowKey: null, 
  };

  componentDidMount() {
    this.setState({ loading: true });

    const { getAllBusinessBranches, getPricingPlanData, getMerchantSubPlan } = this.props;

    this.getBusinessBranch();

    this.getBusinessBranchSub();

    
    getPricingPlanData({
      plan_name: "Staff",
    })
      .then(res => {
        const { price } = res.data;

        this.setState({ staffPrice: price });
      })
      .catch(err => console.log(err));

    getPricingPlanData({
      plan_name: "Branch",
    })
      .then(res => {
        const { price } = res.data;

        this.setState({ branchPrice: price });
      })
      .catch(err => console.log(err));

    getPricingPlanData({
      plan_name: "Ecommerce",
    })
      .then(res => {
        const { price } = res.data;

        this.setState({ ecommercePrice: price });
      })
      .catch(err => console.log(err));
  }

  static getDerivedStateFromProps(props, state) {
    if (props.error) {
      return {
        loading: false,
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  getBusinessBranchSub = () => {
    this.props.getMerchantSubPlan()
    .then((res)=>{
      const splitReference = res.data.transaction_reference.split("_");
      
      this.setState({
        staffPaidFor: splitReference.find(item => item.toLowerCase().includes("staff")).split(":")[1],
        branchPaidFor: splitReference.find(item => item.toLowerCase().includes("branch")).split(":")[1]
      });
      
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  getBusinessBranch = () => {
    this.props.getAllBusinessBranches().then(res => {
      this.setState({ loading: false });
      let staff = [];
      let branches = res.data;
      branches.map((branch)=>{
        staff.push(...branch.staff)
      })
      this.setState({ allStaff: staff })
    });
  }
  
  onDeleteStaff = staff => {
    this.setState({ isDeletingStaff: true });
    this.props.deleteStaff(staff.id).then(res => {
      if (res && res.status === 200) {
        this.props.getAllBusinessBranches();
        message.success("This staff has been archived", toastMessageDuration);
        this.setState({ isDeletingStaff: false });
      } else {
        this.setState({ isDeletingStaff: false });
      }
    });
    message.destroy();
  };

  toggleEditBranchModal = (record) => {
    this.setState({
      selectedBranch: record,
      openEditBranchModal: true
    })
  }

  onArchiveStaff = staff => {
    this.setState({ isDeletingStaff: true });
    this.props.archiveStaff(staff.id).then(res => {
      if (res && res.status === 200) {
        this.props.getAllBusinessBranches();
        message.success("This staff has been archived", toastMessageDuration);
        this.setState({ isDeletingStaff: false });
      } else {
        this.setState({ isDeletingStaff: false });
      }
    });
    message.destroy();
  };

  toggleEditStaff = () => {
    this.setState({ isEditStaffModalOpen: !this.state.isEditStaffModalOpen });
  };

  openAddStaffModal = () => {
    const { user } = this.props;

    if (user.subscription_plan === "Free") {
      Modal.info({
        title: strings.upgradeYourPlan,
      });
      return;
    } else {
      this.setState({
        isAddNewStaffModalOpen: true,
      });
    }
  };

  closeAddStaffModal = () => {
    this.setState({
      isAddNewStaffModalOpen: false,
    });
  };

  toggleAddBranchModal = () => {
    this.setState({
      isAddNewBranchModalOpen: !this.state.isAddNewBranchModalOpen,
    });
  };

  archiveCurrentBranch = (id) => {
    this.setState({ deleteLoading: true })
    this.props.archiveBranch(id).then(res => {
      if (res && res.status === 200) {
        this.props.getAllBusinessBranches();
        Modal.success({
          title: strings.thisBranchHasBeenArchived,
          content: strings.plsNoteStaffTransfer,
          onOk: () => {
            this.setState({ deleteLoading: false, openArchiveModal: false });
          }
        })
      } else {
        this.setState({ deleteLoading: false });
      }
    });
    message.destroy();
  }

  onExpandRow = (expanded, record) => {
    this.setState({
      expandedRowKey: expanded ? record.key : null,
    });
  };

  

  render() {
    const columns = [
      {
        title: strings.name,
        dataIndex: "name",
      },
      {
        title: strings.address,
        dataIndex: "address_line1",
      },
      {
        title: strings.actions,
        // dataIndex: "actions",
        dataIndex: "action",
        key: "action",
        render: (text, record) => (
          <div style={{display: 'flex', alignItems: "center", gap:5}}>
            {!record.name.toLowerCase().includes("home") && <EditOutlined onClick={() => this.toggleEditBranchModal(record)} />}
            {!record.name.toLowerCase().includes("home") && <DeleteOutlined onClick={() => {
              this.setState({ branchToArchive: record.id, openArchiveModal: true });
            }} />}
          </div>
        ),
      },
    ];

    const { allBranches, user } = this.props;
    const {
      isAddNewStaffModalOpen,
      isAddNewBranchModalOpen,
      isEditStaffModalOpen,
      isEditStaffPasswordModalOpen,
      isDeletingStaff,
      loading,
      currentStaff,
      currentBranchId,
      openArchiveModal,
      branchToArchive,
      deleteLoading,
      openPaymentModal,
      priceToPay,
      selectedBranch,
      openEditBranchModal,
      expandedRowKey
    } = this.state;

    return (
      <div className="page-container products">
        {/* Main Content */}
        <main>
          <Card
            title={strings.branches}
            extra={
              <>
                <Popover
                  placement="topLeft"
                  title={strings.add}
                  content={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Button
                        icon={<PlusOutlined />}
                        onClick={this.toggleAddBranchModal}
                        style={{ marginBottom: "20px" }}
                      >
                        {strings.addNewBranch}
                      </Button>
                      <Button
                        icon={<PlusOutlined />}
                        onClick={this.openAddStaffModal}
                      >
                        {strings.addNewStaff}
                      </Button>
                    </div>
                  }
                >
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={this.toggleAddProductModal}
                  >
                    {strings.addNew}
                  </Button>
                </Popover>
              </>
            }
            loading={loading}
          >
            <section className="products-table mt-40">
              <CustomDataTable
                rowKey="id" 
                columns={columns}
                dataSource={allBranches.map(branch => {
                  branch.key = branch.id;
                  return branch;
                })}
                defaultExpandAllRows={false}
                expandedRowKeys={expandedRowKey ? [expandedRowKey] : []} // Only one expanded row
                onExpand={this.onExpandRow} // Handle row expansion
                expandedRowRender={(props, index, indent, expanded) => {
                  return <StaffTable
                    {...props}
                    onDeleteStaff={this.onArchiveStaff}
                    toggleEditStaff={() =>
                      this.setState({ isEditStaffModalOpen: true })
                    }
                    isDeletingStaff={isDeletingStaff}
                    setCurrentStaff={(data) => this.setState({currentStaff: data, currentBranchId: props.id})}
                  />
                }}
              />
            </section>
          </Card>

          {/* Add Staff Modal */}
          {isAddNewStaffModalOpen ? (
            <AddNewStaffModal
              visible={isAddNewStaffModalOpen}
              openModal={this.openAddStaffModal}
              closeModal={this.closeAddStaffModal}
              branches={allBranches}
              staffPrice={this.state.staffPrice}
              user={user}
              staffPaidFor={this.state.staffPaidFor}
              allStaff={this.state.allStaff}
              getBusinessBranch={this.getBusinessBranch}
            />
          ) : null}
          {/* Add Staff Modal */}

          {/* Add Branch Modal */}
          {isAddNewBranchModalOpen ? (
            <AddNewBranchModal
              visible={isAddNewBranchModalOpen}
              toggleModal={this.toggleAddBranchModal}
              branchPrice={this.state.branchPrice}
              ecommercePrice={this.state.ecommercePrice}
              user={user}
              branchPaidFor={this.state.branchPaidFor}
              branches={allBranches}
              getBusinessBranchSub={this.getBusinessBranchSub}
            />
          ) : null}
          {/* Add Branch Modal */}

          {/* Edit Staff Modal */}
          {isEditStaffModalOpen && (
            <EditStaffModal
              visible={isEditStaffModalOpen}
              openModal={() => this.setState({ isEditStaffModalOpen: true })}
              closeModal={() => this.setState({ isEditStaffModalOpen: false, currentStaff: null })}
              branches={allBranches}
              currentStaff={currentStaff}
              editStaffPassword={() =>
                this.setState({ isEditStaffPasswordModalOpen: true })
              }
              user={user}
            />
          )}
          {isEditStaffPasswordModalOpen ? (
            <ChangeStaffPasswordModal
              showChangePassword={isEditStaffPasswordModalOpen}
              handleShowChangePassword={() =>
                this.setState({ 
                  isEditStaffPasswordModalOpen: false, 
                  isEditStaffModalOpen: false, 
                  currentStaff: null 
                })
              }
              currentStaff={currentStaff}
              currentBranchId={currentBranchId}
              getBusinessBranch={this.getBusinessBranch}
            />
          ) : null}

          {openArchiveModal && (
            <Modal
              open={openArchiveModal}
              onCancel={() => this.setState({ openArchiveModal: false })}
              title="Archive Branch"
              onOk={() => this.archiveCurrentBranch(branchToArchive)}
              okButtonProps={{loading: deleteLoading}}
            >
              <p>{strings.areYouSureYouWantArchiveBranch}</p>
            </Modal>
          )}

          {/* Edit branch address */}
          {openEditBranchModal && (
            <EditBranchModal 
              open={openEditBranchModal}
              branchDetails={selectedBranch}
              onCancel={() => this.setState({ openEditBranchModal: false })}
              getBusinessBranch={this.getBusinessBranch}
            />
          )}
           
          {/* Edit Staff Modal */}
        </main>
        {/* Main Content */}
      </div>
    );
  }
}

Branches.propTypes = {
  allBranches: PropTypes.arrayOf(PropTypes.object).isRequired,
  addNewBusinessBranch: PropTypes.func.isRequired,
  getAllBusinessBranches: PropTypes.func.isRequired,
  deleteStaff: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
  allBranches: state.branch.allBranches,
  error: state.error,
});

export default connect(mapStateToProps, {
  addNewBusinessBranch,
  getAllBusinessBranches,
  getPricingPlanData,
  deleteStaff,
  getMerchantSubPlan,
  archiveBranch,
  archiveStaff
})(Branches);
