import React, { forwardRef, Fragment } from 'react';
import strings from '../../../../../strings';
import moment from 'moment';


const ComponentToPrint = forwardRef(({ order, transaction, user }, ref)=>{
    return (
        <div ref={ref}>
            <div className="orderprint">
                <h2>Order Details</h2>
                <div>
                    <div className="orderprintitems contact-info padding-left padding-right">
                        <h3>{user.employer ? user.employer.business_name : user.business_name}</h3>
                    </div>
                    <div className="orderprintitems contact-info padding-left padding-right">
                        <h3>Order Number:</h3>
                        <p>
                            {order.order_id} 
                        </p>
                    </div>
                    <div className="orderprintitems contact-info padding-left padding-right">
                        <h3>{strings.orderCreatedAt}:</h3>
                        <p>
                         {moment(order.created_at).format("DD-MM-YYYY")}
                        </p>
                    </div>
                    <div className="orderprintitems contact-info padding-left padding-right">
                        <h3>{strings.createdTime}:</h3>
                        <p>
                            {moment(order.created_at).format("hh:mm:ss A")}
                        </p>
                    </div>
                    <div className="orderprintitems contact-info padding-left padding-right">
                        <h3>{strings.status}:</h3>
                        <p>
                            {order.status}
                        </p>
                    </div>
                    <div className="orderprintitems contact-info padding-left padding-right">
                        <h3>Order Notes:</h3>
                        <p>
                            {order.orderNotes}
                        </p>
                    </div>
                    <div className='orderprintitems contact-info padding-left padding-right'>
                        <h3>{strings.customerName}: </h3>
                        <p>
                            {order.customer.first_name} 
                            {order.customer.last_name ? order.customer.last_name : ""}
                        </p>
                    </div>
                    <div className='orderprintitems contact-info padding-left padding-right'>
                        <h3>Customer Email: </h3>
                        <p>
                            {order.customer.email ? order.customer.email : ""}
                        </p>
                    </div>
                    <div className='orderprintitems contact-info padding-left padding-right'>
                        <h3>Customer Phone Number: </h3>
                        <p>
                            {order.customer.phone_number ? order.customer.phone_number : ""}
                        </p>
                    </div>
                    <div className='orderprintitems contact-info padding-left padding-right'>
                        <h3>Customer Delivery Address: </h3>
                        <p>
                            {order.customer.delivery_address ? order.customer.delivery_address : ""}
                        </p>
                    </div>
                    <div className='orderprintitems contact-info padding-left padding-right'>
                        <h3>Total: </h3>
                        <p>
                            {user.employer ? user.employer.currency : user.currency}{order.total ? order.total : ""}
                        </p>
                    </div>
                 <h3 className='orderprintitems contact-info padding-left padding-right'>Item(s) Purchased</h3>
                    <div className='orderprintitems contact-info padding-left padding-right'>
                    <table>
                        <tbody>
                            {transaction &&
                                transaction.map((product, key) => (
                                    <Fragment key={key}>
                                    <tr>
                                        <td colSpan="3" className="bold">
                                        <div className="padding-left">
                                            {product.name || product.product_name}
                                        </div>
                                        <div>
                                            {product.variants && product.variants.length > 0 ? (
                                            <div className="image padding-left">
                                                {product.variants.map((variant) => {
                                                return `${variant.type} : ${variant.value}`;
                                                })}
                                            </div>
                                            ) : null}
                                        </div>
                                        </td>
                                    </tr>

                                    <tr className="item-list">
                                        <td className="bold" colSpan="1">
                                        {product.product_quantity || product.quantity}
                                        </td>
                                        <td className="bold" colSpan="1">{`@${
                                        product.product?.variant?.price ||
                                        (product.variants && product.variants[0]?.price) ||
                                        product.product?.original_price ||
                                        product.original_price
                                        }`}</td>
                                        <td className="bold padding-right" colSpan="1">{`${
                                        user.employer ? user.employer.currency : user.currency
                                        }${(
                                        Number(
                                            product.product?.variant?.price ||
                                            (product.variants && product.variants[0]?.price) ||
                                            product.product?.original_price ||
                                            product.original_price
                                        ) * Number(product.product_quantity || product.quantity)
                                        ).toFixed(2)}`}</td>
                                    </tr>
                                </Fragment>
                            ))}
                        </tbody>
                    </table>
                    
                    </div>
                    <div className="footer loyalty-balance">
                        {/* <p className="">{strings.callCashierToCompletePayment}.</p> */}
                        <p>---</p>
                        <p className="smallfont">POWERED BY LOYSTAR ☺</p>
                        <span className="smallfont">WWW.LOYSTAR.CO</span>
                    </div>
                </div>
            </div>
        </div>
    )
})


export default ComponentToPrint;

