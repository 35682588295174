export default class ProductSupplies {
  constructor(client) {
    this.client = client;
  }

  supplyProduct(data) {
    return this.client.post("/supplies/add_product", {
      data: { ...data },
    });
  }

  addSupplier(data) {
    return this.client.post("/suppliers", {
      data: { ...data },
    });
  }

  editSupplier(id, data) {
    return this.client.put(`/suppliers/${id}`, {
      data: { ...data },
    });
  }

  deleteSupplier(id) {
    return this.client.delete(`/suppliers/${id}`);
  }

  getSuppliers() {
    return this.client.get("/suppliers");
  }

  getProductSupplies() {
    return this.client.get("/supplies");
  }

  getSupplyEvents(from, to) {
    return this.client.get(`/supplies?start_date=${from}&end_date=${to}`);
  }

  createProductOrder(data) {
    return this.client.post("/purchase_orders", {
      purchase_order: { ...data },
    });
  }    

  getAllPurchaseOrder(pageNumber=1, pageSize=30, begin, end) {
    if(begin && end){
      return this.client.get(`/purchase_orders?from=${begin}&to=${end}&page[number]=${pageNumber}&page[size]=${pageSize}`);
    }
    return this.client.get(`/purchase_orders?page[number]=${pageNumber}&page[size]=${pageSize}`);
  } 

  getBranchPurchaseOrder(business_branch_id, pageNumber=1, pageSize=30, begin, end) {
    if(begin && end){
      return this.client.get(`/purchase_orders/business_branch/${business_branch_id}?from=${begin}&to=${end}&page[number]=${pageNumber}&page[size]=${pageSize}`);
    }
    return this.client.get(`/purchase_orders/business_branch/${business_branch_id}?page[number]=${pageNumber}&page[size]=${pageSize}`);
  } 

  returnPurchaseOrder(id, data) {
    return this.client.patch(`/purchase_orders/${id}/process_return`, data);
  } 

  savePurchaseOrderToJournal(id, data) {
    return this.client.patch(`/purchase_orders/${id}/purchase_order_journal`, data);
  } 
  
  getPurchaseOrderHistories(pageNumber=1, pageSize=30, begin, end) {
    return this.client.get("/product_histories");
  } 
  
  getOnePurchaseOrder(uuid) {
    return this.client.get(`/purchase_orders/supplier_view/${uuid}`);
  }  //

  getSupplierProducts(supplier_id) {
    return this.client.get(`/suppliers/${supplier_id}/products`);
  }

  receiveInventory(id, data) {
    return this.client.patch(`/purchase_orders/${id}/receive_supplies`, data);
  }    

  updateOrderStatus(id, data) {
    return this.client.patch(`/purchase_orders/${id}/update_status`, data);
  }

  updatePurchaseOrder(id, data) {
    return this.client.patch(`/purchase_orders/${id}`, data);
  }

  backdatePurchaseOrder(data) {
    return this.client.patch(`/purchase_orders/backdate_purchase_order`, data);
  }
  
  supplierReject(uuid) {
    return this.client.patch(`/purchase_orders/reject/${uuid}`);
  }

  supplierAccept(uuid) {
    return this.client.patch(`/purchase_orders/accept/${uuid}`);
  }
}
