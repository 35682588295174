import React, { useState } from "react";
import { Modal, Input, DatePicker, Select, Button, message, Switch } from "antd";
import { Form } from "@ant-design/compatible";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import { allCountryCodes } from "../../../../../../components/PhoneNumberInput/country_data";
import banks from "../../../../../../banks.json";
import "./index.scss";
import api from "../../../../../../api";

// Redux
import { connect } from "react-redux";
import {
  editCustomer,
  getAllCustomers,
} from "../../../../../../redux/actions/customerActions";
import strings from "../../../../../../strings";

const FormItem = Form.Item;
const Option = Select.Option;


const dropdownOptions = [
  "Saloon",
  "SUV & Space Bus",
  "Truck",
  "14 Seater Bus",
  "18 Seater Bus",
  "32 Seater Bus",
  "36 Seater Bus",
  "Pickup Truck",
  "Cargo Truck",
];


const RenderFields = ({ getFieldDecorator, customFields,  }) => {
  const [value, setValue] = useState(null);
  return (
    <>
      <FormItem label="Custom Field">
        <Select
          onChange={val => setValue(val)}
          size="large"
          placeholder="Select custom field"
        >
          {customFields.map(field => (
            <Option value={field}>{field}</Option>
          ))}
        </Select>
      </FormItem>
      {value && (
        <FormItem label={value}>
          {getFieldDecorator(value)(
            value.slice(0, 12).toLowerCase() === "vehicle type" ? (
              <Select size="large">
                {dropdownOptions.map(option => (
                  <Option value={option}>{option}</Option>
                ))}
              </Select>
            ) : (
              <Input size="large" />
            )
          )}
        </FormItem>
      )}
    </>
  );
};

class EditCustomerModal extends React.Component {
  state = {
    loading: false,
    country: "",
    state: "",
    fieldNumbers: 0,
    customFields: [],
  };

  componentDidMount() {
    const { form, customer } = this.props;
    const { date_of_birth, bank_ussd_code } = customer;

    this.getFields();

    // const selectedBank =
    //   bank_ussd_code && banks.filter(bank => bank.short === bank_ussd_code);

    form.setFieldsValue({
      ...customer,
      bank_ussd_code: bank_ussd_code
        ? banks.filter(bank => bank.short === bank_ussd_code)[0].name
        : "",
      date_of_birth: date_of_birth ? moment(date_of_birth, "YYYY/MM/DD") : null,
    });
    this.setState({
      country: customer.country || "",
      state: customer.state || "",
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ loading: false });
    }
  }

  getFields = () => {
    api.HttpClient.get("/merchants/get_custom_fields").then(res => {
      if (res.status === 200) {
        this.setState({ customFields: res.data.data });
      }
    });
  };

  handleEditCustomer = () => {
    const { form, editCustomer, customer, customers, closeModal } = this.props;
    const { country, state } = this.state;

    form.validateFields((err, values) => {
      if (err) return message.error("Please enter all required fields!");;
      const customValues = [];

      for (let field of this.state.customFields) {
        if (values[field])
          customValues.push({
            name: field.toLowerCase(),
            value: values[field],
          });
      }
      try {
        const date_of_birth = values.date_of_birth
          ? values.date_of_birth.format("YYYY-MM-DD")
          : null;

        let phoneNumber =
          values.phone_number[0] === "+"
            ? values.phone_number
            : `+${values.phone_number}`;

        phoneNumber = phoneNumber.split(" ").join("");

        const selectedBank =
          values.bank_ussd_code &&
          banks.filter(bank => bank.name === values.bank_ussd_code);

        const isExistingEmail = email =>
          customers.find(customer => customer.email === values.email);

        const isExistingPhone = phone =>
          customers.find(customer => customer.phone_number === phoneNumber);

        if (values.email !== customer.email && isExistingEmail(values.email)) {
          Modal.error({
            title: strings.customerWithEmailAlreadyExists,
          });
          return;
        }

        if (
          customer.phone_number !== phoneNumber &&
          isExistingPhone(phoneNumber)
        ) {
          Modal.error({
            title: strings.customerWithPhoneAlreadyExists,
          });
          return;
        }

        this.setState({ loading: true });
        // console.log(values);
        // delete values
        const { 
          address_line1, 
          address_line2,
          sex, 
          postcode,
          notes,
          last_name,
          first_name,
          email,
          allow_overdraft
        } = values
        editCustomer({
          address_line1, 
          address_line2,
          sex, 
          postcode,
          notes,
          last_name,
          first_name,
          email,
          date_of_birth,
          id: customer.id,
          customer_id: customer.id,
          phone_number: phoneNumber,
          bank_ussd_code: selectedBank ? selectedBank[0].short : "",
          state,
          country,
          custom_data: customValues,
          allow_overdraft
        })
          .then(res => {
            if (res.status && res.status === 200) {
              this.props.form.resetFields();
              this.props.getAllCustomers();
              Modal.success({
                title: strings.customerWasSuccessfullyEdited,
              });

              this.setState({ loading: false });
              closeModal();
            }
          })
          .catch(error => {
            this.setState({ loading: false });
          });
      } catch (error) {
        this.setState({ loading: false });
        Modal.success({
          title: strings.anErrorOccured,
        });
      }
    });
  };

  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ state: val });
  }

  getDefaultCountry() {
    const {
      customer: { phone_number },
    } = this.props;
    if (phone_number[0] === "+") {
      const code = allCountryCodes[phone_number.substring(1, 4)];
      return code ? code[0] : "ng";
    }
    return "ng";
  }

  render() {
    const {
      showModal,
      closeModal,
      form: { getFieldDecorator },
      user,
      isCustomerWalletActive,
      customer
    } = this.props;

    const isCommission =
      user.commission === null ||
      (user.employer && user.employer.commission === null);

    const { loading, country, state, fieldNumbers, customFields } = this.state;
    const renderFields = number =>
      new Array(number).fill(
        <RenderFields
          getFieldDecorator={getFieldDecorator}
          customFields={customFields}
        />
      );
    return (
      <Modal
        title={strings.editCustomer}
        open={showModal}
        okText={strings.editCustomer}
        cancelText="Cancel"
        okButtonProps={{ loading: loading }}
        onCancel={closeModal}
        onOk={this.handleEditCustomer}
        style={{ top: 20 }}
        destroyOnClose
      >
        <Form layout="vertical" >
          <FormItem label={strings.firstName}>
            {getFieldDecorator("first_name", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseEnterCustomerFirstName,
                },
              ],
            })(<Input size="large" />)}
          </FormItem>

          <FormItem label={strings.lastName}>
            {getFieldDecorator("last_name", {
              rules: [
                {
                  required: false,
                  message: strings.pleaseEnterLastName,
                },
              ],
            })(<Input size="large" />)}
          </FormItem>

          <FormItem label={strings.email} type="email">
            {getFieldDecorator("email", {
              rules: [
                {
                  required: false,
                  message: strings.pleaseEnterCustomerEmail,
                },
              ],
            })(<Input size="large" />)}
          </FormItem>

          {/* <FormItem label="Phone Number (Include Country Code)" type="tel">
            {getFieldDecorator("phone_number", {
              rules: [
                {
                  required: true,
                  message: "Please enter customer's phone number"
                }
              ]
            })(
              <ReactPhoneInput
                containerStyle={{
                  border: '0 !important',
                  width: '100% !important',
                  height: '40px'
                }}
                inputStyle={{
                  border: '1px solid #d9d9d9',
                  width: '100% !important',
                  height: '40px'
                }}
                defaultCountry={this.getDefaultCountry()}
                overideClasses="ant-col ant-form-item-control-wrapper"
              />
            )}
          </FormItem> */}
          <FormItem label={strings.phoneNumberIncludeCountry} type="tel">
            {getFieldDecorator("phone_number", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseEnterCustomerPhoneNumber,
                },
              ],
            })(
              <PhoneInput
                inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
                inputStyle={{
                  border: "1px solid #d9d9d9",
                  width: "100%",
                  height: "40px",
                }}
                // inputProps={{
                //   className: "ant-col ant-form-item-control-wrapper",
                //   style: {
                //     border: "1px solid #d9d9d9",
                //     width: "100% !important",
                //     height: "40px",
                //   },
                // }}
              />
            )}
          </FormItem>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <FormItem label={strings.dateOfBirth}>
              {getFieldDecorator("date_of_birth", {
                rules: [
                  {
                    type: "object",
                  },
                ],
              })(<DatePicker size="large" style={{ width: "100%" }} />)}
            </FormItem>

            <FormItem label={strings.sex}>
              {getFieldDecorator("sex", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseSelectCustomerGender,
                  },
                ],
              })(
                <Select size="large">
                  <Option value="M">{strings.male}</Option>
                  <Option value="F">{strings.female}</Option>
                </Select>
              )}
            </FormItem>
          </div>

          <FormItem label={strings.addressLine1}>
            {getFieldDecorator("address_line1", {
              rules: [
                {
                  required: false,
                  message: strings.pleaseEnterCustomerAddressLine,
                },
              ],
            })(<Input size="large" />)}
          </FormItem>

          <FormItem label={strings.addressLine2}>
            {getFieldDecorator("address_line2", {
              rules: [
                {
                  required: false,
                  message: strings.pleaseEnterCustomerOtherAddress,
                },
              ],
            })(<Input size="large" />)}
          </FormItem>

          {user.currency === "NGN" && !isCommission && (
            <FormItem label={strings.bank}>
              {getFieldDecorator("bank_ussd_code")(
                <Select
                  placeholder={strings.selectBank}
                  allowClear
                  size="large"
                >
                  {banks.map((bank, key) => (
                    <Option key={bank.code} value={bank.name}>
                      {bank.name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          )}
{/* isCustomerWalletActive */}
          {isCustomerWalletActive && (
            <FormItem label={strings.allowOverdraft}>
              {getFieldDecorator("allow_overdraft")(
                <Switch defaultChecked={customer.allow_overdraft}  />
              )}
            </FormItem>
          )}

          <div className="field-wrapper">
            <label>{strings.country}</label>
            <CountryDropdown
              value={country}
              defaultOptionLabel={strings.selectCountry}
              onChange={val => this.selectCountry(val)}
              className="ant-input-lg ant-input"
            />
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              className="field-wrapper"
              style={{ margin: 0, marginTop: "8px", width: "220px" }}
            >
              <label style={{ marginBottom: "7px" }}>
                {strings.stateOrRegionOrProvince}
              </label>
              <RegionDropdown
                country={country}
                value={state}
                defaultOptionLabel={strings.selectRegion}
                onChange={val => this.selectRegion(val)}
                className="ant-input-lg ant-input"
              />
            </div>

            <FormItem label={strings.postCode}>
              {getFieldDecorator("postcode", {
                rules: [
                  {
                    required: false,
                    message: strings.pleaseEnterPostCode,
                  },
                ],
              })(<Input size="large" />)}
            </FormItem>
          </div>

          <FormItem label={strings.customerNotes}>
            {getFieldDecorator("notes")(
              <Input.TextArea size="large" rows={3} />
            )}
          </FormItem>

          {renderFields(fieldNumbers)}

          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button
              disabled={fieldNumbers === customFields.length}
              type="primary"
              onClick={() =>
                this.setState(prev => ({ fieldNumbers: prev.fieldNumbers + 1 }))
              }
            >
              Add Custom Fields
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

const EditCustomerForm = Form.create()(EditCustomerModal);

EditCustomerForm.propTypes = {
  error: PropTypes.string.isRequired,
  editCustomer: PropTypes.func.isRequired,
  getAllCustomers: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
  customers: state.customer.allCustomers,
  error: state.error,
  isCustomerWalletActive: state.appStore.isCustomerWalletActive
});

export default connect(mapStateToProps, { editCustomer, getAllCustomers })(
  EditCustomerForm
);
