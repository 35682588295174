export default class Product {
  constructor(client) {
    this.client = client;
  }

  getAllProducts(page, pageSize = 5000, time_stamp = 0) {
    return this.client.post(
      `/get_latest_merchant_products?page[number]=${page}&page[size]=${pageSize}`,
      {
        data: { time_stamp },
      }
    );
  }

  getAllProductByCategory(page, pageSize = 5000, category, time_stamp = 0) {
    return this.client.post(
      `/get_latest_merchant_products?page[number]=${page}&page[size]=${pageSize}&category_id=${category}`,
      {
        data: { time_stamp },
      }
    );
  }

  getAllProductsCostAndSellingPrice() {
    return this.client.get(
      `/merchant_latest_tcp_and_tsp`
    );
  }

  getBranchInventory(id) {
    return this.client.get(
      `/merchant_reports/${id}/inventory_value`
    );
  } 

  getMerchantSummary(){
    return this.client.get(
      `/merchant_reports/summary`
    );
  }

//https://api.loystar.co/api/v2/get_latest_merchant_products?page[number]=1&page[size]=$1000000000
  getAllBranchProducts(merchantId, branchId, pageNumber=1, pageSize = 5000, category, time_stamp = 0) {
    if(category){
      //
      return this.client.get(
        `/merchants/products_list/${merchantId}?business_branch_id=${branchId}&page[number]=${pageNumber}&page[size]=${pageSize}&category_id=${category}`
      );
    }
    return this.client.get(
      `/merchants/products_list/${merchantId}?business_branch_id=${branchId}&page[number]=${pageNumber}&page[size]=${pageSize}`
    );
  }

  addNewProduct(productData) {
    return this.client.post(
      "/add_product",
      // { data: productData }
      productData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  editProduct(product_id, productData) {
    return this.client.patch(`/products/${product_id}`, productData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  deleteProduct(product_id) {
    return this.client.post(`/products/set_delete_flag_to_true/${product_id}`);
  }//

  getProductHistory(pageNumber=1, pageSize = 30, begin, end) {
    if(begin && end){
      return this.client.get(`/product_histories?from=${begin}&to=${end}&page[number]=${pageNumber}&page[size]=${pageSize}`);
    }
    return this.client.get(`/product_histories?page[number]=${pageNumber}&page[size]=${pageSize}`);
  }

  getSingleProductHistory(id, pageNumber=1, pageSize = 30, begin, end) {
    if(begin && end){
      return this.client.get(`/product/histories/${id}?from=${begin}&to=${end}&page[number]=${pageNumber}&page[size]=${pageSize}`);
    }
    return this.client.get(`/product/histories/${id}?page[number]=${pageNumber}&page[size]=${pageSize}`);
  }

  sendInventory(productData) {
    return this.client.post("/add_inventory", { data: productData });
  }

  
  sendAllInventory(productData) {
    return this.client.post("/merchant/send_products_to_business_branches", { data: productData });
  }

  sendInventoryWithVariant(productData) {
    return this.client.post("/merchant/products/add_inventory_with_variants", { data: productData });
  }

  updateInventory(productData) {
    return this.client.post("/add_inventory", { data: productData });
  }

  addCustomQuantity( payload ) {
    return this.client.post(`/products/custom_quantity`,{ data: payload }, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  addProductBundle( payload ) {
    return this.client.post(`/products/custom_quantity`,{ data: payload }, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  editCustomQuantity( id, payload ) {
    return this.client.put(`/products/custom_quantity/${id}`,{ data: payload }, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  addBundleProduct( formData ) {
    return this.client.post(`/products/bundle`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  editBundleProduct( id, payload ) {
    return this.client.put(`/products/bundle/${id}`,{ data: payload }, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  editBundleProducts( id, formData ) {
    return this.client.patch(`/products/bundle/${id}`,formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  deleteBundleProduct(id) {
    return this.client.delete(`/products/bundle/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getOneBundleProduct(id) {
    return this.client.get(`/merchant/bundle/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getAllMerchantBundleProducts( merchant_id ) {
    return this.client.get(`/merchant/${merchant_id}/bundles`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  deleteCustomQuantity( id ) {
    return this.client.delete(`/products/custom_quantity/${id}`);
  }

  importProduct(formData) {
    return this.client.post("/products/import_products", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
