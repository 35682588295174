import React, { useState } from "react";
import { Modal, Menu, Button, Dropdown, message } from "antd";
import strings from "../../../../../../../../strings";
import {
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  LIST_TIERED_CUSTOMERS,
} from "../../../Loyalties/constants";
import { useMutation } from "@apollo/client";
import EditMemberModal from "./EditMemberModal";

const MemberDetailModal = ({ visible, closeModal, currentMember }) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [ openDeletModal, setOpenDeleteModal ] = useState(false);
  const {
    id: customerId,
    customer_id,
    first_name,
    last_name,
    email,
    points,
    tiers,
    created_at,
  } = currentMember;

  const [deleteCustomer, { loading: deleteCustomerLoading }] = useMutation(
    DELETE_CUSTOMER,
    {
      onCompleted: data => {
        closeModal();
        data.deleteCustomer &&
          Modal.success({
            title: DELETE_CUSTOMER_SUCCESS,
            okButtonProps: {
              id: "btn-component",
            },
          });
      },
      onError: error => {
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: LIST_TIERED_CUSTOMERS }],
    }
  );

  const confirmDeleteMember = async (customerId, customer_id) => {
    let seletcedId = customerId.length < 6 ? customerId : customer_id < 6 ? customer_id : customerId
    await deleteCustomer({ variables: { id: String(customer_id) } })
  };

  const menu = (
    <Menu key={1}>
      <Menu.Item key={1} onClick={() => setEditModalVisible(true)}>
        <span>{strings.edit}</span>
      </Menu.Item>
      <Menu.Item key={2} onClick={() => {
        setOpenDeleteModal(true)
        // 
        }}>
        <span>Unenroll</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {editModalVisible && (
        <EditMemberModal
          visible={editModalVisible}
          closeModal={() => setEditModalVisible(false)}
          customer={currentMember}
        />
      )}
      <Modal
        title={strings.memberDetails}
        className="loyalty"
        open={visible}
        onCancel={() => closeModal()}
        footer={[
          <Button key="cancel" onClick={() => closeModal()}>
            {strings.cancel}
          </Button>,
          <Dropdown key={98298} overlay={menu} placement="topCenter" arrow>
            <Button
              id="btn-component"
              key={"action-btn"}
              className="ant-btn-text"
              type="primary"
            >
              {strings.actions}
            </Button>
          </Dropdown>,
        ]}
      >
        <div>
          <p>
            <strong>{strings.nameOfCustomer}:</strong> {first_name || "N/A"}{" "}
            {last_name || "N/A"}
          </p>

          <p>
            <strong>{strings.email}:</strong> {email || "N/A"}
          </p>

          <div className="customer_points">
            <div style={{ display: "flex" }}>
              <span>
                <strong>{strings.points}: </strong>{" "}
              </span>

              <span>
                {points && points.length > 0
                  ? points.map(({ points_value, loyalty_name }, index) => {
                      return (
                        <span key={index}>
                          {loyalty_name} - {points_value}
                        </span>
                      );
                    })
                  : "---"}
              </span>
            </div>
          </div>

          <div className="tier_enrolled">
            <p>
              <strong>Tier Enrolled:</strong>{" "}
              {tiers && tiers.length > 0
                ? tiers.map(({ name }, index) => {
                    return <span key={index}> {name}</span>;
                  })
                : "---"}
            </p>
          </div>

          <p>
            <strong>{strings.createdOn}:</strong>{" "}
            {new Date(created_at).toLocaleString()}
          </p>
        </div>
      </Modal>
        
      {openDeletModal && (
        <Modal
          open={openDeletModal}
          onCancel={() => setOpenDeleteModal(false)}
          title="Unenroll Customer"
          onOk={()=>confirmDeleteMember(customerId, customer_id)}
          okButtonProps={{
            loading: deleteCustomerLoading
          }}
        >
          <div>
            <p>Do you want to unenroll this customer from loyalty?</p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default MemberDetailModal;
