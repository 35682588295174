import React, { forwardRef, Fragment } from 'react'
import strings from '../../../../../strings'

const Receipt = forwardRef(({ purchaseOrder, user, handleFindReceivedHistories, handleFindReceivedHistoriesPaid }, ref)=>{
   return (
    <div ref={ref}>
        <div className="orderprint">
            <h2 className='padding-left padding-right'>Receipt of Purchase Order</h2>
            <div>
                <div className="orderprintitems contact-info padding-left padding-right">
                    <h3>{user.employer ? user.employer.business_name : user.business_name}</h3>
                </div>
                <div className="orderprintitems contact-info padding-left padding-right" style={{marginLeft: 30}}>
                    {user.image && (
                        <img src={user.image} style={{ width: 100, height: 100 }} alt="merchant logo" />
                    )}
                    {user.employer && user.employer.image && (
                        <img src={user.employer.image} style={{ width: 100, height: 100 }} alt="merchant logo" />
                    )}
                </div>
                <div className="orderprintitems contact-info padding-left padding-right">
                    <h3>{strings.supplier}:</h3>
                    <p>
                    {purchaseOrder && purchaseOrder.user && purchaseOrder.user.first_name } {purchaseOrder && purchaseOrder.user && purchaseOrder.user.last_name || ""}
                    </p>
                </div>
                <div className="orderprintitems contact-info padding-left padding-right">
                    <h3>{strings.businessName}:</h3>
                    <p>
                    {purchaseOrder && purchaseOrder.supplier && purchaseOrder.supplier.business_name}
                    </p>
                </div>
                <div className="orderprintitems contact-info padding-left padding-right">
                    <h3>{strings.status}:</h3>
                    <p>
                    {purchaseOrder &&purchaseOrder.status}
                    </p>
                </div>
                <div className="orderprintitems contact-info padding-left padding-right">
                    <h3>{strings.receivedBy}:</h3>
                    <p>
                    {purchaseOrder && purchaseOrder.purchase_order_histories.length > 0 ? purchaseOrder.purchase_order_histories[purchaseOrder.purchase_order_histories.length - 1].triggered_by || "N/A" : "N/A"}
                    </p>
                </div>
                {purchaseOrder && purchaseOrder.purchase_order_histories.length > 3 && <div className="orderprintitems contact-info padding-left padding-right">
                    <h3>{strings.dateReceived}:</h3>
                    <p>
                    {handleFindReceivedHistories(purchaseOrder)}
                    </p>
                </div>}
                {purchaseOrder && purchaseOrder.purchase_order_histories.length > 4 && <div className="orderprintitems contact-info padding-left padding-right">
                    <h3>{strings.datePaid}:</h3>
                    <p>
                    {handleFindReceivedHistoriesPaid(purchaseOrder)}
                    </p>
                </div>}
               
               
                <h3 className='orderprintitems contact-info padding-left padding-right'>Item(s) To Be Received</h3>
                <div className='orderprintitems contact-info padding-left padding-right'>
                    <table>
                        <tbody>
                            {purchaseOrder &&
                                purchaseOrder.purchase_order_items.map((purchase, index) => (
                                    <li key={index} style={{display: "flex", justifyContent: "space-between", listStyleType: "circle"}}>
                                    <div style={{display: "flex", flexDirection: "column",listStyleType: "circle"}}>
                                      <span style={{fontSize: 14}}>
                                        {purchase.name} - {user.currency || user.employer.currency} {purchase.price}
                                      </span>
                                      <div style={{display: "flex", alignItems: "center", gap:8}}>
                                        <span  style={{fontSize: 12}}>
                                            <strong>{strings.orderedQty}</strong> - {purchase.ordered_quantity}
                                        </span>
                                        <span style={{fontSize: 12}}>
                                        <strong>{strings.receivedQty}</strong> - {purchase.received_quantity || 0}
                                        </span>
                                        {purchase.order_return_item && <span style={{fontSize: 12}}>
                                          <strong>{strings.returnedQty}</strong> - {purchase.returned_quantity || 0}
                                        </span>}
                                      </div>
                                    </div>
                                </li>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="footer loyalty-balance">
                    {/* <p className="">{strings.callCashierToCompletePayment}.</p> */}
                    <p>---</p>
                    <p className="smallfont">POWERED BY LOYSTAR ☺</p>
                    <span className="smallfont">WWW.LOYSTAR.CO</span>
                </div>
            </div>
        </div>
    </div>
    )
})

export default Receipt