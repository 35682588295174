import React, { useState, useEffect } from "react";
import { Modal, Button, Input, message, Radio, Select, InputNumber } from "antd";
import { Form } from "@ant-design/compatible";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  EDIT_TIER,
  EDIT_TIER_SUCCESS,
  GET_PARTNER,
  LIST_PARTNERS,
  LIST_TIERS,
} from "../../../../constants";
import strings from "../../../../../../../../../../strings";

const Option = Select.Option;

const EditTierModal = ({
  visible,
  closeModal,
  currentTier,
  loyalty_id,
  form,
}) => {
  const [rewardSponsor, setRewardSponsor] = useState("self");
  const [partnersId, setPartnersId] = useState("");
  const [rewards, setRewards] = useState([]);

  const {
    id: tierId,
    nameOfTier,
    min_spending_target,
    max_spending_target,
    reward,
    instruction,
    is_reward_self,
  } = currentTier;

  useEffect(() => {
    if (currentTier) {
      form.setFieldsValue({
        ...currentTier,
        name: nameOfTier,
        rewardSponsor: is_reward_self,
        min_spending_target,
        max_spending_target,
        instruction,
        reward,
      });
    }
  }, []);

  const [rewardPartner, setPartner] = useState(null);

  const { loading: partnersLoading, data: partnersData } = useQuery(
    LIST_PARTNERS,
    {
      onError(error) {
        message.error(error.message, 5);
      },
    }
  );

  useEffect(() => {
    if (currentTier.is_reward_self === true) {
      return;
    } else {
      const data = partnersData;
      !partnersLoading &&
        data.listPartners &&
        data.listPartners.length > 0 &&
        data.listPartners.forEach(partner => {
          partner.tier.length > 0 &&
            partner.tier.forEach(tier => {
              if (tier.id === currentTier.id) {
                setPartner(partner);
              }
            });
        });
    }
  }, [partnersLoading]);

  const [getPartner, { loading: getPartnerLoading }] = useLazyQuery(
    GET_PARTNER,
    {
      onCompleted: data => {
        setRewards(data.getPartner.rewards);
      },
      onError: error => {
        message.error(error.message, 5);
      },
    }
  );

  const [editTier, { loading: isEditTierLoading }] = useMutation(EDIT_TIER, {
    onCompleted: () => {
      closeModal();

      form.resetFields();

      Modal.success({
        title: EDIT_TIER_SUCCESS,
        okButtonProps: {
          id: "btn-component",
        },
      });
    },
    onError: error => {
      message.error(error.message, 5);
    },
    refetchQueries: [
      //  { query: GET_LOYALTY, variables: { id: currentPageId } },
      { query: LIST_TIERS, variables: { loyalty_id } },
      { query: LIST_PARTNERS },
    ],
  });

  const onEdit = async values => {
    await editTier({
      variables: {
        id: tierId,
        data: {
          name: values.name,
          min_spending_target: Number(values.min_spending_target),
          max_spending_target: Number(values.max_spending_target),
          magnification: Number(values.magnification),
          is_reward_self: values.rewardSponsor === "self" ? true : false,
          reward: values.reward,
          instruction: values.instruction,
          partners_id: values.rewardPartner ? partnersId : null,
        },
      },
    });
  };

  const handleEditTier = async () => {
    try {
      const values = await form.validateFields();
      // console.log(values);
      await onEdit(values);
    } catch (error) {
      if (error) return;
    }
  };

  const { getFieldDecorator } = form;

  return (
    <Modal
      title={`${strings.edit} ${currentTier.name}`}
      className="create_tier_modal"
      open={visible}
      onCancel={() => {
        Modal.confirm({
          title: strings.stopEditingTierConfirmation,
          onOk: closeModal,
          onCancel: () => {},
        });
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            Modal.confirm({
              title: strings.stopEditingTierConfirmation,
              onOk: closeModal,
              onCancel: () => {},
            });
          }}
        >
          {strings.cancel}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isEditTierLoading}
          id="btn-component"
          onClick={() => handleEditTier()}
        >
          {strings.save}
        </Button>,
      ]}
    >
      <Form layout="vertical" name="create_tier" id="tierForm">
        <Form.Item label={strings.nameOfTier} className="wrap">
          {getFieldDecorator("name", {
            rules: [{ required: true }],
          })(<Input />)}
        </Form.Item>

        <div className="spending_target">
          <Form.Item label={strings.minimumSpendingTarget} className="wrap">
            {getFieldDecorator("min_spending_target", {
              rules: [
                {
                  required: true,
                  message: strings.minimumSpendingTargetRequired,
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label={strings.maximumSpendingTarget} className="wrap">
            {getFieldDecorator("max_spending_target", {
              rules: [
                {
                  required: true,
                  message: strings.maximumSpendingTargetRequired,
                },
              ],
            })(<Input />)}
          </Form.Item>
        </div>

        <Form.Item label={strings.magnification} className="wrap">
            {getFieldDecorator("magnification", {
              rules: [
                {
                  required: true,
                  message: strings.magnification,
                },
              ],
            })(<InputNumber style={{width: "100%"}} />)}
          </Form.Item>

        <Form.Item name="rewardSponsor" label={strings.rewardSponsor}>
          {getFieldDecorator("rewardSponsor", {
            rules: [
              { required: true, message: strings.pleaseChooseARewardSponsor },
            ],
          })(
            <Radio.Group
              defaultValue={is_reward_self ? "self" : "partner"}
              onChange={e => {
                setRewardSponsor(e.target.value);
              }}
            >
              <Radio defaultChecked={is_reward_self} value="self">
                {strings.self}
              </Radio>
              <Radio value="partner" defaultChecked={!!rewardPartner}>
                {strings.partner}
              </Radio>
            </Radio.Group>
          )}
        </Form.Item>

        {rewardSponsor === "partner" ? (
          <>
            <Form.Item name="rewardPartner" label={strings.rewardPartner}>
              {getFieldDecorator("rewardPartner", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseSelectRewardPartner,
                  },
                ],
              })(
                <Select
                  loading={partnersLoading}
                  placeholder={strings.pleaseSelectRewardPartner}
                  defaultValue={rewardPartner?.company_name}
                  onChange={(_, option) => {
                    setPartnersId(option.key);
                    getPartner({ variables: { id: option.key } });
                  }}
                >
                  {partnersData && partnersData.listPartners
                    ? partnersData.listPartners.map(partner => (
                        <Option key={partner.id} value={partner.company_name}>
                          {partner.company_name}
                        </Option>
                      ))
                    : null}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              label={strings.rewards}
              rules={[
                {
                  required: true,
                  message: strings.pleaseSelectAReward,
                },
              ]}
            >
              {getFieldDecorator("reward", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseSelectAReward,
                  },
                ],
              })(
                <Select
                  loading={getPartnerLoading}
                  placeholder={strings.pleaseSelectAReward}
                  onChange={option => {}}
                >
                  {rewards && rewards.length !== 0
                    ? rewards.map(reward => (
                        <Option key={reward.id} value={reward.name}>
                          {reward.name}
                        </Option>
                      ))
                    : null}
                </Select>
              )}
            </Form.Item>

            <Form.Item label={strings.instructionsOnRedeemingReward}>
              {getFieldDecorator("instruction", {
                rules: [
                  { required: true, message: strings.pleaseFillInThisField },
                ],
              })(<Input />)}
            </Form.Item>
          </>
        ) : null}

        {rewardSponsor === "self" ? (
          <Form.Item
            name="reward"
            label={strings.rewards}
            rules={[{ required: true }]}
          >
            {getFieldDecorator("reward", {
              rules: [{ required: true }],
            })(<Input />)}
          </Form.Item>
        ) : null}
      </Form>
    </Modal>
  );
};

export default Form.create()(EditTierModal);
