import React from "react";

// Form container style
import "react-phone-input-2/lib/style.css";
import { withRouter } from "react-router-dom";
import {  Input, Button, Checkbox, Modal } from "antd";
import { Icon, Form } from "@ant-design/compatible";
import { AllCountryDetails } from "./country";
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleReCaptcha } from "react-google-recaptcha-v3"
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';


// Redux
import { connect } from "react-redux";
import {
  signupMerchant,
  checkEmailAvailability,
  checkPhoneAvailability,
} from "../../../redux/actions/authActions";

import "./index.scss";
import "../index.scss";
import strings from "../../../strings";
import { formatGoogleAddress } from "../../../utils/formatAddressFromApi";

const FormItem = Form.Item;

const user = {
  full_name: "",
  email: "",
  phoneNumber: "",
  businessName: "",
  verified: true,
};

const libraries = ['places'];
class Signup extends React.PureComponent {
  state = {
    loading: false,
    country: null,
    state: "",
    countries: null,
    countryCodes: null,
    businessTypes: [
      { id: 1, type: strings.foodAndDrinks },
      { id: 2, type: strings.salonAndBeauty },
      { id: 3, type: strings.fashionAndAccessories },
      { id: 4, type: strings.gymAndFitness },
      { id: 5, type: strings.travelAndHotel },
      { id: 6, type: strings.homeAndGifts },
      { id: 7, type: strings.washingAndCleaning },
      { id: 8, type: strings.gadgetsAndElectronics },
      { id: 9, type: strings.groceries },
      { id: 10, type: strings.others },
      { id: 11, type: strings.photography },
      { id: 12, type: strings.bookstore },
      { id: 13, type: strings.healthcare },
    ],
    isChecked: false,
    isEmailLoading: false,
    isPhoneLoading: false,
    utm_source: "",
    refcode: "",
    captcha_response: null,
    autocomplete: null,
    address_line1: null,
    longitude: null,
    latitude: null,
    state: null
  };

  componentDidMount() {
    const { form, countryData } = this.props;

    // fetch all countries with their currencies and calling codes 
    
    // fetch(`https://restcountries.com/v2/all`)
    //   .then(res => res.json())
    //   .then(res => {
    //     console.log("res", res);
    //     let country = res.find(
    //       country =>
    //         country.alpha2Code.toLowerCase() ===
    //         countryData.countryCode.toLowerCase()
    //     );

    //     // console.log({ res, country });

    //     this.setState({ country });
    //   });
    this.setCountryDetails();
    // Set Referral Code Input Field if present
    const url = this.props.location.search;

    const referral_info = url && url.split("&"); // expecting  ["?refcode=2234", "utm_source=ig"]

    // console.log(referral_info.filter(item => item.includes("ref_code")));

    // const refcode = referral_info.length && referral_info[0].split("?")[1];
    const refcode =
      referral_info.length &&
      referral_info.filter(item => item.includes("ref_code"));

    const utm_source =
      referral_info.length &&
      referral_info.filter(item => item.includes("utm_source"));

    // console.log({ refcode, utm_source });

    if (refcode.length) {
      form.setFieldsValue({
        ref_code: refcode[0].split("=")[1],
      });
    }

    if (utm_source.length) {
      this.setState({
        utm_source: utm_source[0].split("=")[1],
      });
    }

    if (refcode && refcode.length) {
      this.setState({ refcode: refcode[0].split("=")[1] });
    }
  }

  setCountryDetails = () => {
    const { countryData } = this.props;

    let country = AllCountryDetails.find(
      country =>
        country.alpha2Code.toLowerCase() ===
        countryData.countryCode.toLowerCase()
    );

    this.setState({ country });
  }

  toggleLoading = () => {
    this.setState({ loading: !this.state.loading });
  };

  // TODO: Submit registration form
  handleSubmit = async e => {
    e.preventDefault();
    const { form, signupMerchant, phone, personalDataFields } = this.props;
    const { country, state, utm_source, refcode, captcha_response, longitude, latitude } = this.state;

    try {
      this.setState({ loading: true });

      const values = await form.validateFields();

      // filter out business type with business typd id
      let business_type = this.state.businessTypes.filter(
        type => Number(personalDataFields.business_type_id) === type.id
      );
      business_type = business_type[0].type;

      // let contact_number =
      //   values.contact_number[0] == "+"
      //     ? values.contact_number
      //     : `+${values.contact_number}`;

      // contact_number = contact_number.split(" ").join("");

      const payload = {
        ...values,
        ...{ business_type },
        ...personalDataFields,
        contact_number: phone,
        country: country.name,
        currency: country.currencies[0].code,
        state,
        // captcha_response,
        longitude,
        latitude
      };

      if (utm_source) {
        payload.signup_source = utm_source;
      }

      // return console.log(payload);

      signupMerchant({
        ...payload,
        state,
        country,
      }).then(res => {
        this.setState({ loading: false });
        const { status } = res;

        // check if merchant registration was successful
        this.setState({ loading: false });
        if (status && status === 200) {
          this.props.form.resetFields();

          // Then Redirect to Dashboard
          this.props.history.push("/d/transactions/today/report");

          Modal.success({
            title: strings.accountCreatedSuccessful,
          });
        } else {
          this.setState({ loading: false });
          Modal.error({
            title: strings.somethingWentWrong,
            content: res.message,
          });
        }
      }).catch(error => {
        this.setState({ loading: false });
        console.log(error);
      })      
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }

    form.validateFields((err, values) => {
      this.setState({ loading: false });
      if (err) return;

      this.setState({ loading: true });
    });
  };

  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ state: val });
  }

  handleChange = (value) => {
    // console.log("value", value);
    this.setState({ captcha_response: value })
  }

  onLoad = (autocomplete) => {
    this.setState({ autocomplete });
  };
  // phone_number

 

  render() {
    // Passed from the Form.create() HOC below {ln: 193}
    const { getFieldDecorator, setFieldsValue } = this.props.form;

    const { loading, address_line1 } = this.state;
    const onPlaceChanged = () => {
      const { form } = this.props;
      const { autocomplete } = this.state;
      const { setFieldsValue } = form;
      if (autocomplete !== null) {
        const place = autocomplete.getPlace();
        const location = place.geometry.location;
  
  
        const formatedAdress = formatGoogleAddress(place.address_components);
        console.log("d", formatedAdress, place);
  
        // setAddress_line1(formatedAdress.addressLine1);
        // setAddress_line2(formatedAdress.addressLine2);
        // setPostal_code(formatedAdress.postalCode);
        // setState(formatedAdress.state);
        // setCountry(formatedAdress.country);
  
        this.setState({
          address_line1: formatedAdress.addressLine1
        });
  
        setFieldsValue({
          postcode: formatedAdress.postalCode,
          state: formatedAdress.state,
          country: formatedAdress.country,
          address_line1: formatedAdress.addressLine1,
          address_line2: formatedAdress.addressLine2
        });
  
        this.setState({
          longitude: location.lng(),
          latitude: location.lat(),
          state: formatedAdress.state,
        })
  
      } else {
        console.log('Autocomplete is not loaded yet!');
      }
    };

    return (
      <div className="" style={{ maxWidth: "500px" }}>
        <div className="">
          <Form layout="vertical" onSubmit={this.handleSubmit}>
            {/* Address Line 1*/}
            <FormItem>
              {getFieldDecorator("address_line1", {
                rules: [
                  {
                    required: true,
                    message: strings.pleaseEnterAddress,
                  },
                ],
              })(
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY} libraries={libraries}>
                <div>
                  <Autocomplete onLoad={this.onLoad} onPlaceChanged={onPlaceChanged}>
                    <Input 
                        // placeholder="address line1"
                        value={address_line1}
                        // name="address_line1"
                        size="large"
                        onChange={(e)=>{
                          this.setState({address_line1: e.target.value});
                          setFieldsValue({
                            address_line1: e.target.value
                          })
                        }}
                        type="text"
                        // placeholder="Search for a place"
                        placeholder={strings.addressLine1}
                        style={{ width: '100%'}}
                        // ref={inputRef}
                        prefix={
                          <Icon
                            type="environment"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                    />
                  </Autocomplete>
                </div>
              </LoadScript>
              )}
            </FormItem>
            {/* =========Address Line 1 */}

            {/* Address Line 2*/}
            <FormItem>
              {getFieldDecorator("address_line2")(
                <Input
                  size="large"
                  prefix={
                    <Icon
                      type="environment"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  placeholder={strings.addressLine2}
                />
              )}
            </FormItem>

            <FormItem style={{ marginBottom: "0" }}>
              {getFieldDecorator("postcode", {
                rules: [
                  {
                    required: false,
                    message: strings.pleaseEnterPostCode,
                  },
                ],
              })(<Input placeholder={strings.postCode} size="large" />)}
            </FormItem>

            {/* Password Input */}
            <FormItem>
              {getFieldDecorator("password", {
                rules: [
                  {
                    message: strings.pleaseEnterPassword,
                    required: true,
                  },
                  {
                    min: 6,
                    message: strings.passwordMustBe6Characters,
                  },
                ],
              })(
                <Input.Password
                  size="large"
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  style={{ marginTop: "20px" }}
                  type="password"
                  placeholder={strings.choosePassword}
                />
              )}
            </FormItem>
            {/* ======Password */}

            {/* ====Referral Code===*/}
            <FormItem label={strings.howDidYouHearLoystar}>
              {getFieldDecorator("ref_code")(
                <Input
                  size="large"
                  type="text"
                  placeholder={strings.referralCode}
                />
              )}
            </FormItem>

            <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%"}}>
              <FormItem>
                {getFieldDecorator("captcha_response", { 
                  rules:[
                    {
                      message: "Check the reCaptcha to continue",
                      required: false,
                    },
                  ]
                })(   
                  // <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECHAPTCHA_SITE_KEY}>
                    <GoogleReCaptcha onVerify={this.handleChange} />
                  // </GoogleReCaptchaProvider>               
                  
                )}
              </FormItem>
            </div>
            
            {/* ====Referral Code===*/}

            <div style={{ marginBottom: "24px" }}>
              <Checkbox
                onChange={e => {
                  e.target.checked
                    ? this.setState({ isChecked: true })
                    : this.setState({ isChecked: false });
                }}
                checked={this.state.isChecked}
              >
                {strings.byClickingTheButton}
                <a
                  href="https://loystar.co/legal/privacy-policy/"
                  target="new"
                  rel="noopener"
                >
                  {strings.termsAndSevice}
                </a>
              </Checkbox>
            </div>

            <Button
              className="submit-btn"
              type="primary"
              size="large"
              block
              htmlType="submit"
              loading={loading}
              disabled={!this.state.isChecked}
              
            >
              {loading ? strings.wereCreatingAccount : strings.proceed}
            </Button>

            {/* <div
              className="form-utils__container"
              style={{ justifyContent: "center" }}
            >
              <Link to="/signin">Sign in</Link>
            </div> */}
          </Form>
        </div>
      </div>
    );
  }
}
const SignupForm = Form.create({
  mapPropsToFields(props) {
    return {
      full_name: Form.createFormField({
        value: user.full_name ? user.full_name : "",
      }),
      businessName: Form.createFormField({
        value: user.businessName ? user.businessName : "",
      }),
      phoneNumber: Form.createFormField({
        value: user.phoneNumber ? user.phoneNumber : "",
      }),
      email: Form.createFormField({
        value: user.email ? user.email : "",
      }),
    };
  },
})(props => <Signup {...props} />);

export default connect(null, {
  signupMerchant,
  checkPhoneAvailability,
  checkEmailAvailability,
})(withRouter(SignupForm));
