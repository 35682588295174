import React from "react";
import { connect } from "react-redux";
import { Modal, Upload, Tooltip, message, Button, Tabs } from "antd";
import { Icon } from "@ant-design/compatible";
import { ExcelRenderer } from "react-excel-renderer";
import { importProduct } from "../../../../../../redux/actions/productActions";
import { DeleteOutlined } from "@ant-design/icons";

// Styles
import "./index.scss";
import strings from "../../../../../../strings";
import api from "../../../../../../api";

const { TabPane } = Tabs;
const { Dragger } = Upload;

const CSV_TEMPLATE_URL =
  "https://docs.google.com/spreadsheets/d/1oCAc_NzhKzvkUkl7_zjmZSzCIC2xiP_s4X-xUUqcJpc/edit?usp=drive_web&ouid=109273388671844171860";

const CSV_VARIANT_TEMPLATE_URL =
  "https://docs.google.com/spreadsheets/d/1w1q0wA8gnSFBXLfxKqSS6O6RC575CR3bxuId5o3CJGA/edit#gid=657852246";

  const GOOGLE_CSV_TEMPLATE_URL =
  "https://docs.google.com/spreadsheets/d/1oCAc_NzhKzvkUkl7_zjmZSzCIC2xiP_s4X-xUUqcJpc";
class ImportProductModal extends React.Component {
  state = {
    fileList: [],
    uploading: false,
    errors: [],
    strangeHeaders: [],
    rows: [],
    isVariant: false,
    isMultiple: false,
    activeKey: "1",
    productList: null,
    fileName: null 
  };

  handleUpload = () => {
    const { fileList } = this.state;
    const { user, importProduct, toggleImportProductModal } = this.props;

    this.setState({ uploading: true });

    const formData = new FormData();

    const data = { merchant_email: user.email, csv_file: fileList[0] };

    for (const key in data) {
      formData.append(key, data[key]);
    }

    importProduct(formData)
      .then(res => {
        this.setState({ uploading: false });

        if (res && res.status === 200) {
          this.setState({ fileList: [] });
          Modal.success({
            title: strings.yourFileQueuedForUpload,
            onOk: () => toggleImportProductModal(),
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ uploading: false });
        Modal.error({
          title: err?.response ? err?.response?.data?.message : err.message ? err.message : strings.thereWasAnErrorPerformingOperation,
        });
      });
  };

  render() {
    const { uploading, errors, strangeHeaders, isVariant, activeKey } = this.state;

    const {
      isImportProductModalVisible,
      toggleImportProductModal,
      importType,
      productList,
      user: { subscription_plan },
      total_products
    } = this.props;

    const props = {
      name: "csv_file",
      multiple: false,
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });

        this.setState({ errors: [] });
      },
      beforeUpload: async file => {
        // Clear Any Errors or Files if Presert
        this.setState({ fileList: [], errors: [], strangeHeaders: [] });
        const { isVariant, isMultiple } = this.state;

        if (!file) {
          message.error(strings.pleaseSelectFile, 5);

          return;
        }
        // Check If File is Excel File
        // const isExcel =
        //   file.type === "application/vnd.ms-excel" ||
        //   file.type ===
        //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

        // Check If File is Excel File

        const isCsv = new RegExp("(.*?).(csv)$");

        if (!isCsv.test(file.name)) {
          this.setState({
            errorMessage: strings.oopsOnlyCsvFilesAllowed,
          });
          message.warning(strings.oopsOnlyCsvFilesAllowed, 5);

          return;
        }

        const isLt5M = file.size / 1024 / 1024 < 5;

        if (!isLt5M) {
          this.setState({
            errorMessage: strings.fileShouldBeSmallerThan5Mb,
          });
          message.warning(strings.fileShouldBeSmallerThan5Mb, 5);

          return;
        }

        let errors = [];
        let strangeHeaders = []; // Headers Not Accepted in CSV

        if(isVariant){
          const acceptedHeaders = [
            "name",
            "category",
            "description",
            "picture",
            "price",
            "cost price",
            "track inventory",
            "unit",
            "quantity",
            "variant_type",
            "variant_value",
            "variant_cost_price",
            "tax type",
            "tax rate",
            "product_sku",
            "product_sku2"
          ]; // Headers Accepted in CSV File
            try {
              const resp = await ExcelRenderer(file);
  
              // console.log(resp.rows);
  
              const {
                rows: [incomingHeaders, ...rest],
              } = resp;
  
              // console.log("data", rest);
  
              incomingHeaders.forEach(val => {
                if (!acceptedHeaders.includes(val.toLowerCase().trim())) {
                  strangeHeaders.push(val);
                }
              });
  
              if (strangeHeaders.length === 0) {
                let products = [];
  
                rest.forEach(item => {
                  const [
                    name,
                    category,
                    description,
                    picture,
                    price,
                    cost_price,
                    track_inventory,
                    unit,
                    quantity,
                    variant_type,
                    variant_value,
                    variant_cost_price,
                    tax_type,
                    tax_rate,
                    product_sku,
                    product_sku2
                  ] = item;
  
                  products.push({
                    name: name === undefined ? "" : name,
                    category: category === undefined ? "" : category,
                    description: description === undefined ? "" : description,
                    price: price === undefined ? "" : price,
                    cost_price: cost_price === undefined ? "" : cost_price,
                    track_inventory:
                      track_inventory === undefined ? "" : track_inventory,
                    unit: unit === undefined ? "" : unit,
                    quantity: quantity === undefined ? "" : quantity,
                    product_sku: product_sku === undefined ? "" : product_sku,
                    tax_type: tax_type === undefined ? "" : tax_type,
                    tax_rate: tax_rate === undefined ? "" : tax_rate,
                    picture: picture === undefined ? "" : picture,
                    variant_type: variant_type === undefined ? "" : variant_type,
                    variant_value: variant_value === undefined ? "" : variant_value,
                    variant_cost_price: variant_cost_price === undefined ? "" : variant_cost_price,
                    product_sku2: product_sku2 === undefined ? "" : product_sku2
                  });
                });
  
                /*
                   Check if Number of Products Imported hasn't exceeded limit set for merchant's plan (i.e Free Plan = 20 products, Basic = 60 Products, pro = Unlimited)
              */
                let limit;
                if (subscription_plan === "Free") {
                  limit = 20 - productList.length;
                  if (products.length > limit) {
                    Modal.warn({
                      title: strings.youHaveExceededTotalNumberOfProducts,
                    });
                    return;
                  }
                } else if (subscription_plan === "Basic") {
                  limit = 60 - productList.length;
                  if (products.length > limit) {
                    Modal.warn({
                      title: strings.youHaveExceededTotalNumberOfProducts,
                    });
                    return;
                  }
                }  else if (products.length > 10000 && subscription_plan !== "Nitro") {
                  limit = 10000;
                  if (products.length > limit) {
                    Modal.warn({
                      title: strings.youNeedToEnableNitroPlan,
                    });
                    return;
                  }
                }else if (total_products >= 10000 && subscription_plan !== "Nitro") {
                  limit = 10000;
                  if (total_products >= limit) {
                    Modal.warn({
                      title: strings.youNeedToEnableNitroPlan,
                    });
                    return;
                  }
                }
  
                // console.log(products);
  
                // Validate Each Product for Simple Product
                products.forEach((product, index) => {
                  const {
                    name,
                    category,
                    price,
                    track_inventory,
                    unit,
                    quantity,
                  } = product;
  
                  const currRow = index + 2;
  
                  // Validate Main Fields
                  if (!name) {
                    errors.push(
                      `${strings.productNameIsRequiredOnRow} ${currRow}`
                    );
                  }
  
                  if (!category) {
                    errors.push(
                      `${strings.productCategoryIsRequiredOnRow} ${currRow}`
                    );
                  }

                  // if (!price) {
                  //   console.log(price);
                  //   errors.push(
                  //     `${strings.productPriceIsRequiredOnRow} ${currRow}`
                  //   );
                  // }
  
                  // Check if Product has Inventory Tracking Enabled or Not
                  if (track_inventory === "Yes") {
                    // Validate Uniy and Quantity Fields
                    if (!unit) {
                      errors.push(
                        `${strings.productUnitIsRequiredOnRow} ${currRow}`
                      );
                    }
  
                    // if (!quantity) {
                    //   errors.push(
                    //     `${strings.productQuantityIsRequiredOnRow} ${currRow}`
                    //   );
                    // }
                  }
                });

                this.setState({ productList: products.length })
              }

            } catch (error) {
              console.log(error);
            }
          
  
        }else if(isMultiple){
          const acceptedHeaders = [
            "product_id",
            "product_sku",
            "custom_qty_1_name",
            "custom_qty_1_qty",
            "custom_qty_1_price",
            "custom_qty_2_name",
            "custom_qty_2_qty",
            "custom_qty_2_price",
          ]; // Headers Accepted in CSV File
            try {
              const resp = await ExcelRenderer(file);
  
              // console.log(resp.rows);
  
              const {
                rows: [incomingHeaders, ...rest],
              } = resp;
  
              // console.log("data", rest);
  
              incomingHeaders.forEach(val => {
                if (!acceptedHeaders.includes(val.toLowerCase().trim())) {
                  strangeHeaders.push(val);
                }
              });
  
              if (strangeHeaders.length === 0) {
                let products = [];
  
                rest.forEach(item => {
                  const [
                    product_id,
                    product_sku,
                    custom_qty_1_name,
                    custom_qty_1_qty,
                    custom_qty_1_price,
                    custom_qty_2_name,
                    custom_qty_2_qty,
                    custom_qty_2_price,
                  ] = item;
  
                  products.push({
                    product_id: product_id === undefined ? "" : product_id,
                    custom_qty_1_name: custom_qty_1_name === undefined ? "" : custom_qty_1_name,
                    custom_qty_1_qty: custom_qty_1_qty === undefined ? "" : custom_qty_1_qty,
                    custom_qty_1_price: custom_qty_1_price === undefined ? "" : custom_qty_1_price,
                    custom_qty_2_name: custom_qty_2_name === undefined ? "" : custom_qty_2_name,
                    custom_qty_2_qty:
                      custom_qty_2_qty === undefined ? "" : custom_qty_2_qty,
                    custom_qty_2_price: custom_qty_2_price === undefined ? "" : custom_qty_2_price,
                    product_sku: product_sku === undefined ? "" : product_sku,
                  });
                });
  
                /*
                   Check if Number of Products Imported hasn't exceeded limit set for merchant's plan (i.e Free Plan = 20 products, Basic = 60 Products, pro = Unlimited)
              */
                let limit;
                if (subscription_plan === "Free") {
                  limit = 20 - productList.length;
                  if (products.length > limit) {
                    Modal.warn({
                      title: strings.youHaveExceededTotalNumberOfProducts,
                    });
                    return;
                  }
                } else if (subscription_plan === "Basic") {
                  limit = 60 - productList.length;
                  if (products.length > limit) {
                    Modal.warn({
                      title: strings.youHaveExceededTotalNumberOfProducts,
                    });
                    return;
                  }
                }
  
                
  
                const newProd = products.slice(0, 1000)
                // Validate Each Product for Simple Product
                newProd.forEach( async (product, index) => {
                  const {
                    product_id,
                    product_sku,
                    custom_qty_1_name,
                    custom_qty_1_qty,
                    custom_qty_1_price,
                    custom_qty_2_name,
                    custom_qty_2_qty,
                    custom_qty_2_price,
                  } = product;

                  const { user } = this.props;
                  if(custom_qty_1_name !== ""){
                    const payload = {
                      name: custom_qty_1_name,
                      price: custom_qty_1_price,
                      quantity: custom_qty_1_qty,
                      barcode: product_sku,
                      product_id,
                      merchant_id: user.id
                    }

                    await api.product.addCustomQuantity( payload )
                  }

                  if(custom_qty_2_name !== ""){
                    const payload = {
                      name: custom_qty_2_name,
                      price: custom_qty_2_price,
                      quantity: custom_qty_2_qty,
                      barcode: product_sku,
                      product_id,
                      merchant_id: user.id
                    }

                    await api.product.addCustomQuantity( payload )
                  }
                
                  

                });

                this.setState({ productList: newProd.length })
              }
            } catch (error) {
              console.log(error);
            }
        }else{
          const acceptedHeaders = [
            "name",
            "category",
            "description",
            "picture",
            "price",
            "cost price",
            "track inventory",
            "quantity",
            "unit",
            "tax type",
            "tax rate",
            "product_sku",
            "product_sku2"
          ]; // Headers Accepted in CSV File
  
          // if (importType === "Simple Product") {
            try {
              const resp = await ExcelRenderer(file);
  
              const {
                rows: [incomingHeaders, ...rest],
              } = resp;
  
              incomingHeaders.forEach(val => {
                if (!acceptedHeaders.includes(val.toLowerCase().trim())) {
                  strangeHeaders.push(val);
                }
              });

              //  console.log("data", strangeHeaders, incomingHeaders);
  
              if (strangeHeaders.length === 0) {
                let products = [];
  
                rest.forEach(item => {
                  const [
                    name,
                    category,
                    description,
                    picture,
                    price,
                    cost_price,
                    track_inventory,
                    quantity,
                    unit,
                    tax_type,
                    tax_rate,
                    product_sku,
                    product_sku2
                  ] = item;
  
                  products.push({
                    name: name === undefined ? "" : name,
                    category: category === undefined ? "" : category,
                    description: description === undefined ? "" : description,
                    price: price === undefined ? "" : price,
                    cost_price: cost_price === undefined ? "" : cost_price,
                    track_inventory:
                      track_inventory === undefined ? "" : track_inventory,
                    unit: unit === undefined ? "" : unit,
                    quantity: quantity === undefined ? "" : quantity,
                    product_sku: product_sku === undefined ? "" : product_sku,
                    tax_type: tax_type === undefined ? "" : tax_type,
                    picture: picture === undefined ? "" : picture,
                    tax_rate: tax_rate === undefined ? "" : tax_rate,
                    product_sku2: product_sku2 === undefined ? "" : product_sku2
                  });
                });
                /*
                   Check if Number of Products Imported hasn't exceeded limit set for merchant's plan (i.e Free Plan = 20 products, Basic = 60 Products, pro = Unlimited)
              */
                let limit;
                if (subscription_plan === "Free") {
                  limit = 20 - productList.length;
                  if (products.length > limit) {
                    Modal.warn({
                      title: strings.youHaveExceededTotalNumberOfProducts,
                    });
                    return;
                  }
                } else if (subscription_plan === "Basic") {
                  limit = 60 - productList.length;
                  if (products.length > limit) {
                    Modal.warn({
                      title: strings.youHaveExceededTotalNumberOfProducts,
                    });
                    return;
                  }
                } else if (products.length > 10000 && subscription_plan !== "Nitro") {
                  limit = 10000;
                  if (products.length > limit) {
                    Modal.warn({
                      title: strings.youNeedToEnableNitroPlan,
                    });
                    return;
                  }
                }else if (total_products >= 10000 && subscription_plan !== "Nitro") {
                  limit = 10000;
                  if (total_products >= limit) {
                    Modal.warn({
                      title: strings.youNeedToEnableNitroPlan,
                    });
                    return;
                  }
                }
               
  
                // Validate Each Product for Simple Product
                products.forEach((product, index) => {
                  const {
                    name,
                    category,
                    price,
                    track_inventory,
                    unit,
                    quantity,
                  } = product;

                  // console.log(product);
  
                  const currRow = index + 2;
  
                  // Validate Main Fields
                  if (!name) {
                    errors.push(
                      `${strings.productNameIsRequiredOnRow} ${currRow}`
                    );
                  }
  
                  if (!category) {
                    errors.push(
                      `${strings.productCategoryIsRequiredOnRow} ${currRow}`
                    );
                  }
  
                  if (!price) {
                    errors.push(
                      `${strings.productPriceIsRequiredOnRow} ${currRow}`
                    );
                  }
  
                  // Check if Product has Inventory Tracking Enabled or Not
                  if (track_inventory === "Yes") {
                    // Validate Uniy and Quantity Fields
                    if (!unit) {
                      errors.push(
                        `${strings.productUnitIsRequiredOnRow} ${currRow}`
                      );
                    }
  
                    // if (!quantity) {
                    //   errors.push(
                    //     `${strings.productQuantityIsRequiredOnRow} ${currRow}`
                    //   );
                    // }
                  }
                });

                this.setState({ productList: products.length })
              }
            } catch (error) {
              console.log("import error",error);
              if (error instanceof TypeError) {
                console.error("TypeError:", error.message);
              } else {
                console.error("Error:", error);
              }
            }
          // } 
        }

        if (strangeHeaders.length > 0) {
          this.setState({ strangeHeaders });
          return;
        }

        if (errors.length > 0) {
          // console.log("Error Length", errors, strangeHeaders);
          this.setState({ errors });
          return;
        }
        this.setState(state => ({
          fileList: [...state.fileList, file],
          fileName: file.name 
        }));

        return false;
      },
      fileList: this.state.fileList,
    };

    const { isNitroActive } = this.props;
    
    const items = [
      {
        key: "1",
        label: "simple products",
        children: 
        <><div className="product-import-wrapper">
              <p className="tutorial-wrapper">
                <span></span>
                {/* <span className="tutorial-link">
                  Click this <a href="#">Link</a> to watch a video on how to use the
                  product import feature
                </span> */}

                <Tooltip
                  title={
                    <span>
                      {strings.clickIconToDownloadCsv}{" "}
                      <a href={CSV_TEMPLATE_URL} rel="noopener" target="blank">
                        {"(" + strings.getGoogleSheetVersion + ")"}
                      </a>
                    </span>
                  }
                >
                  <a
                    href={`${
                      importType === "Simple Product"
                        ? CSV_TEMPLATE_URL
                        : CSV_TEMPLATE_URL
                    }`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Icon type="download" style={{ fontSize: "30px" }} />
                  </a>
                </Tooltip>
              </p>

              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">{strings.clickOrDragFileToUpload}</p>
              </Dragger>

              <div style={{ marginTop: "1.5rem", marginLeft: "0.5rem" }}>
                {errors.length > 0 &&
                  errors.map((err, key) => (
                    <span key={key} style={{ display: "block", color: "#f5222d" }}>
                      {err}
                    </span>
                  ))}

                {strangeHeaders.length > 0 && (
                  <span style={{ display: "block", color: "#f5222d" }}>
                    {strings.missingOrInvalidColumnHeader}
                  </span>
                )}
              </div>
              {this.state.fileList.length > 0 && (
                <p style={{ marginTop: "-1.5rem", fontSize:12 }}>
                 {this.state.fileName }{" "}<DeleteOutlined onClick={() => this.setState({ fileList: []})} />
                </p>
                
              )}
              {/* {this.state.fileList.length > 0 && (
                <p style={{ marginTop: "1rem" }}>
                  {strings.youHaveImported} {this.state.productList}{" "}
                  {strings.products}
                </p>
              )} */}
            </div></>
        
      },
      {
        key: "2",
        label: "variant products",
        children: [
          <div className="product-import-wrapper">
          <p className="tutorial-wrapper">
            <span></span>
            {/* <span className="tutorial-link">
              Click this <a href="#">Link</a> to watch a video on how to use the
              product import feature
            </span> */}

            <Tooltip
              title={
                <span>
                  {strings.clickIconToDownloadCsv}{" "}
                  <a href={CSV_VARIANT_TEMPLATE_URL} rel="noopener" target="blank">
                    {"(" + strings.getGoogleSheetVersion + ")"}
                  </a>
                </span>
              }
            >
              <a
                href={`${
                  importType !== "Simple Product"
                    ? CSV_VARIANT_TEMPLATE_URL
                    : CSV_VARIANT_TEMPLATE_URL
                }`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Icon type="download" style={{ fontSize: "30px" }} />
              </a>
            </Tooltip>
          </p>

          <Dragger {...props} customRequest={() => {}}>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">{strings.clickOrDragVariantFileToUpload}</p>
          </Dragger>

        <div style={{ marginTop: "1.5rem", marginLeft: "0.5rem" }}>
          {errors.length > 0 &&
            errors.map((err, key) => (
              <span key={key} style={{ display: "block", color: "#f5222d" }}>
                {err}
              </span>
            ))}

          {strangeHeaders.length > 0 && (
            <span style={{ display: "block", color: "#f5222d" }}>
              {strings.missingOrInvalidColumnHeader}
            </span>
          )}
        </div>
        {this.state.fileList.length > 0 && (
          <p style={{ marginTop: "-1.5rem", fontSize:12 }}>
            {this.state.fileName }{" "}<DeleteOutlined onClick={() => this.setState({ fileList: []})} />
          </p>
          
        )}
        {this.state.fileList.length > 0 && (
          <p style={{ marginTop: "1rem" }}>
            {strings.youHaveImported} {this.state.productList}{" "}
            {strings.products}
          </p>
        )}
        {/* {this.state.rows.length > 2 && this.state.fileList.length > 0 && (
          <p style={{ marginTop: "2rem" }}>
            {strings.youHaveImported} {this.state.rows.length - 1}{" "}
            {strings.products}
          </p>
        )} */}
        </div>
        ]
      },
      {
        key: "3",
        label: "Multiple products(Custom Quantity)",
        // disabled:  false : true,
        children: [
          <div className="product-import-wrapper">
          <p className="tutorial-wrapper">
            <span></span>
            {/* <span className="tutorial-link">
              Click this <a href="#">Link</a> to watch a video on how to use the
              product import feature
            </span> */}

            <Tooltip
              title={
                <span>
                  {strings.clickIconToDownloadCsv}{" "}
                  <a href={CSV_VARIANT_TEMPLATE_URL} rel="noopener" target="blank">
                    {"(" + strings.getGoogleSheetVersion + ")"}
                  </a>
                </span>
              }
            >
              <a
                href={`${
                  importType !== "Simple Product"
                    ? CSV_VARIANT_TEMPLATE_URL
                    : CSV_VARIANT_TEMPLATE_URL
                }`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Icon type="download" style={{ fontSize: "30px" }} />
              </a>
            </Tooltip>
          </p>

          <Dragger {...props} customRequest={() => {}}>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">{strings.clickOrDragToAddMultipleCustomQty}</p>
          </Dragger>

        <div style={{ marginTop: "1.5rem", marginLeft: "0.5rem" }}>
          {errors.length > 0 &&
            errors.map((err, key) => (
              <span key={key} style={{ display: "block", color: "#f5222d" }}>
                {err}
              </span>
            ))}

          {strangeHeaders.length > 0 && (
            <span style={{ display: "block", color: "#f5222d" }}>
              {strings.missingOrInvalidColumnHeader}
            </span>
          )}
        </div>

        {this.state.fileList.length > 0 && (
          <p style={{ marginTop: "-1.5rem", fontSize:12 }}>
            {this.state.fileName }{" "}<DeleteOutlined onClick={() => this.setState({ fileList: []})} />
          </p>
          
        )}
        {/* {this.state.fileList.length > 0 && (
          <p style={{ marginTop: "1rem" }}>
            {strings.youHaveImported} {this.state.productList}{" "}
            {strings.products}
          </p>
        )} */}
        </div>
        ]
      }
    ]


    return (
      <Modal
        title={`Product Import`}
        open={isImportProductModalVisible}
        destroyOnClose
        onCancel={toggleImportProductModal}
        footer={[
          <Button
            key="upload-btn"
            onClick={this.handleUpload}
            type="primary"
            disabled={this.state.fileList.length === 0}
            loading={uploading}
          >
            {strings.upload}
          </Button>,
        ]}
      >
        <Tabs
          activeKey={activeKey}
          onChange={(activeKey) => {
            this.setState({ activeKey })
            if(activeKey === "1"){
              this.setState({ isVariant: false, isMultiple: false })
            }else if(activeKey === "3"){
              this.setState({ isVariant: false, isMultiple: true })
            }else{
              this.setState({ isVariant: true, isMultiple: false })
            }
          }}
          items={items}
        ></Tabs>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  isNitroActive: state.appStore.isNitroActive
});
export default connect(mapStateToProps, { importProduct })(ImportProductModal);
