export function checkError(input) {
    if (typeof input === "string") {
        // If input is a string, return it directly
        return input;
    }

    if (typeof input === "object" && input !== null) {
        const { errors, full_messages } = input;

        // Return messages from `full_messages` if available
        if (Array.isArray(full_messages) && full_messages.length > 0) {
            return full_messages.join(", ");
        }

        // Return messages from `errors` if `full_messages` isn't available
        if (typeof errors === "object" && errors !== null) {
            const errorMessages = Object.values(errors).flat();
            if (errorMessages.length > 0) {
                return errorMessages.join(", ");
            }
        }

        // Fallback if no errors are found
        return "Unknown error occurred.";
    }

    // Handle unexpected types
    return "Invalid input type.";
}
