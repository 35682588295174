import { Button, InputNumber, message, Modal, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sendAllInventory, sendInventory } from "../../../../../../redux/actions/productActions";
import strings from "../../../../../../strings";


const Option = Select.Option;

const BulkSendInventoryModal = ({
  products,
  productIds,
  showModal,
  closeModal,
  cancelSelection,
  sendInventory,
  updateProducts,
  branches,
  sendInvType,
  sendAllInventory
}) => {
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [sendInventoryLoading, setSendInventoryLoading] = useState(false);
  const [sendInventoryPayload, setSendInventoryPayload] = useState([]);
  const [ quantity, setQuantity ] = useState(null);

  const productsObj = {};
  for (let i of products) {
    productsObj[i.id] = i;
  }

  const productsToSend = productIds 
    && productIds
    .map((id) => productsObj[id])
    .filter((prod) => prod.track_inventory && Number(prod.quantity) > 0);

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Amount to Send",
      dataIndex: "quantity",
      render: (val, record) => (
        <InputNumber
          min={1}
          max={val}
          defaultValue={1}
          onChange={(val) => updateProductQuantity(record.id, val)}
        />
      ),
    },
    // {
    //   title: "Select variant to Send",
    //   dataIndex: "variants",
    //   render: (val, record) => {
    //     return val.length > 0 ? <Select placeholder={strings.searchForVariant} showSearch optionFilterProp="children" mode="multiple" size="large" style={{width: "100%"}}>
    //       {val.length > 0 && val.map((variant, index) => {
    //         return <Option key={index} value={variant.id}>{variant.type} - {variant.value}</Option>
    //       })}
    //     </Select> : null
    //   },
    // },
  ];

  const handleSendAllInventory = () => {
    if (!selectedBranch) {
      return Modal.error({ title: "Please select destination branch." });
    }
    if (!quantity) {
      return Modal.error({ title: "Please specify a quantity!" });
    }

    const payload = {
      business_branch_id: selectedBranch,
      quantity
    }
    setSendInventoryLoading(true);
    sendAllInventory(payload)
    .then((res) => {
        Modal.success({
          title: res.data.message || strings.inventorySentSuccessfully,
        });
        setSendInventoryLoading(false);
        updateProducts();
        closeModal();
    })
    .catch((err) => {
        setSendInventoryLoading(false);
        Modal.error({
          title: err?.response ? err?.response.data?.message || err.response.message : err?.message ? err.message : "An error occured while sending inventory",
        });
        closeModal();
        updateProducts();
    });
  }

  const handleSendInventory = () => {
    if (!selectedBranch) {
      return Modal.error({ title: "Please select destination branch." });
    }
    setSendInventoryLoading(true);

    // return console.log("produc", sendInventoryPayload);
    const sendProducts = sendInventoryPayload.map((payload) =>
      sendInventory(payload)
    );

    Promise.all(sendProducts)
      .then((res) => {
        Modal.success({
          title: strings.inventorySentSuccessfully,
        });
        setSendInventoryLoading(false);
        updateProducts();
        cancelSelection();
        closeModal();
      })
      .catch((err) => {
        setSendInventoryLoading(false);
        Modal.error({
          title: err?.response ? err?.response.data?.message || err.response.message : err?.message ? err.message : "An error occured while sending inventory",
        });
        closeModal();
        updateProducts();
      });
  };

  const myBranches = branches.filter(
    (branch) => branch.name.toLowerCase() !== "ecommerce"
  );

  const updateProductQuantity = (id, quantity) => {
    if (!quantity) return message.error("Please enter quantity");
    const product = sendInventoryPayload.find((prod) => prod.product_id === id);
    const otherProducts = sendInventoryPayload.filter(
      (prod) => prod.product_id !== id
    );
    setSendInventoryPayload([
      ...otherProducts,
      {
        ...product,
        quantity,
      },
    ]);
  };

  useEffect(() => {
    const payload = productsToSend.map((product, index) => ({
      quantity: sendInventoryPayload.length > 0 ? sendInventoryPayload[index].quantity : 1,
      business_branch_id: selectedBranch,
      product_id: product.id,
    }));
    setSendInventoryPayload(payload);
  }, [selectedBranch]);

  return (
    <Modal
      open={showModal}
      onCancel={closeModal}
      width={600}
      title="Send Bulk Inventory"
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,
        sendInvType === "single" && <Button
          type="primary"
          onClick={handleSendInventory}
          loading={sendInventoryLoading}
        >
          Send
        </Button>,
        sendInvType === "multiple" && <Button
          type="primary"
          onClick={handleSendAllInventory}
          loading={sendInventoryLoading}
        >
          Send
        </Button>,
      ]}
    >
      <div style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}>
        <p style={{marginBottom: 5}}><b>Branch:</b></p>
        <Select
          showSearch
          placeholder={strings.searchForBranch}
          onChange={(val) => setSelectedBranch(val)}
          optionFilterProp="children"
          size="large"
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {myBranches &&
            myBranches.map((branch) => (
              <Select.Option key={branch.id} value={branch.id}>
                {branch.name}
              </Select.Option>
            ))}
        </Select>
      </div>
      {sendInvType === "multiple" && <div>
        <InputNumber style={{width: "100%"}} placeholder={strings.quantity} size="large" onChange={(value) => setQuantity(value)} />
      </div>}
      {sendInvType === "single" && <Table columns={columns} dataSource={productsToSend} />}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  branches: state.branch.allBranches,
  error: state.error,
});
export default connect(mapStateToProps, {
  sendInventory,
  sendAllInventory
})(BulkSendInventoryModal);
